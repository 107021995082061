import React, { useState } from "react";
import "../pages/HeroDetailPage.css";

const Abilities = ({ abilities, heroName }) => {
  const [activeAbilityIndex, setActiveAbilityIndex] = useState(0); // Track active ability

  return (
    <div className="abilities">
      <h2>Abilities</h2>
      <div className="ability-tabs">
        {abilities.map((ability, index) => (
          <img
            key={index}
            src={`/heroes/${heroName
              .toLowerCase()
              .replace(/\s+/g, "_")}/assets/ability_${ability.name
              .toLowerCase()
              .replace(/\s+/g, "_")}.png`}
            alt={ability.name}
            className={`ability-icon ${
              index === activeAbilityIndex ? "active" : ""
            }`}
            onClick={() => setActiveAbilityIndex(index)}
          />
        ))}
      </div>
      <div className="ability-description">
        <h3>{abilities[activeAbilityIndex].name}</h3>
        <p>{abilities[activeAbilityIndex].description}</p>
        <ul className="ability-stats">
          <li>
            <span>Cooldown: </span>
            <span>{abilities[activeAbilityIndex].cooldown}</span>
          </li>
          <li>
            <span>Cast Range: </span>
            <span>{abilities[activeAbilityIndex].castRange}</span>
          </li>
          <li>
            <span>Duration: </span>
            <span>{abilities[activeAbilityIndex].duration}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Abilities;
