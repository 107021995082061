import React from "react";

const MasteringTheBasicsGuide = () => {
  return (
    <>
      <div>
        <h1>Deadlock FAQ / Glossary (By Kyo)</h1>
        <h2>Deadlock FAQ / Glossary</h2>
        <p>By Kyo</p>
        <h3>INDEX</h3>
        <ol>
          <li>Playtest / Feedback Forums</li>
          <li>Menus / Settings</li>
          <li>
            Gameplay
            <ul>
              <li>Heroes</li>
              <li>Mechanics</li>
              <li>Laning</li>
              <li>Map / Objectives</li>
            </ul>
          </li>
          <li>Glossary of Common Terms</li>
          <li>Details / Stats / Trivia</li>
        </ol>

        <h3>1. Playtest / Feedback Forums</h3>
        <h4>How do I join the feedback forums?</h4>
        <p>
          At the bottom right of the in-game main menu click on the “Join the
          Feedback Forums” button, and you will be prompted to enter an email.
          Once you have login information, you can check it again by clicking on
          “View Profile” at the top right of the main menu, followed by “View
          Forum Login Info” at the top left. The forums can be found at{" "}
          <a href="https://forums.playdeadlock.com/">
            https://forums.playdeadlock.com/
          </a>
        </p>

        <h4>How do I report bugs?</h4>
        <p>
          Bugs should be reported in the above forums, in the Bug Reports
          sub-forum.
        </p>

        <h4>Why are the forums marked as private?</h4>
        <p>
          The private section of the forums can only be viewed by developers of
          the game, but anyone can post in them. This is to protect the
          individuality of feedback.
        </p>

        <h4>How long will the test last?</h4>
        <p>
          Unless something changes, the test will last until the game is
          released.
        </p>

        <h4>How do I invite my friends?</h4>
        <p>
          At the bottom right of the in-game main menu, click on the “Invite
          your Friends” button.
        </p>

        <h4>Does this game have MMR? Can I view my MMR?</h4>
        <p>
          The game uses a hidden MMR system to create fair and consistent
          matches. There is no way to view your MMR.
        </p>

        <h4>Am I allowed to stream the game or make content for it?</h4>
        <p>
          Yes! You are now allowed to create content and stream the game. The
          Twitch directory for Deadlock is{" "}
          <a href="https://www.twitch.tv/directory/category/deadlock-1">
            https://www.twitch.tv/directory/category/deadlock-1
          </a>
        </p>

        <h3>2. Menus / Settings</h3>
        <h4>How do I change servers?</h4>
        <p>
          The test is currently on NA Central (Chicago), EU (Stockholm), Asia
          (Hong Kong), South America (Santiago) and Oceania (Sydney). In order
          to change your preferred server, you can open the console with the F7
          key and enter:
        </p>
        <pre>
          citadel_region_override -1 (automatic)
          <br />
          citadel_region_override 0 (NA-C)
          <br />
          citadel_region_override 1 (EU)
          <br />
          citadel_region_override 2 (Asia)
          <br />
          citadel_region_override 3 (SA)
          <br />
          citadel_region_override 5 (OCE)
        </pre>

        <h4>
          Can I convert my CS2 / other Source game sensitivity to this game?
        </h4>
        <p>
          Multiply the sensitivity you use in your other Source game by{" "}
          <strong>0.5</strong>
        </p>

        <h4>How do I report someone for griefing/toxicity?</h4>
        <p>
          Once the match ends, you can click on the [Report] beneath their
          portrait to report them. You may need to mouse over their portrait for
          it to show up.
        </p>

        <h4>How do I view my match history?</h4>
        <p>
          You can view your match history from the main menu by clicking on the
          “View Profile” button at the top right.
        </p>

        <h4>Are there replays/demos?</h4>
        <p>
          Yes, from your profile mentioned in the previous question you can
          select any match and click on “Download Replay” at the bottom right.
        </p>

        <h4>If someone leaves my game, what do I do?</h4>
        <p>
          If someone abandons your match, you can either play it out or leave
          once the message on-screen appears telling you that it is safe to
          leave without penalty.
        </p>

        <h3>3. Gameplay</h3>
        <h4>3a. Heroes</h4>
        <h4>What are the hero roles?</h4>
        <p>
          Heroes are very flexible in this game, roles like “support” and
          “carry” don’t really exist. The items and skill order you pick will
          largely define your role in a match, even if some heroes are more
          inclined towards utility/damage/tankiness.
        </p>

        <h4>What hero is good for a new player?</h4>
        <p>
          Whoever you think looks the most appealing. Failing that, here are a
          couple of recommendations based on general player taste:
        </p>
        <ul>
          <li>
            If you like to be tanky and disruptive, try Abrams or Mo and Krill.
          </li>
          <li>If you come primarily from shooter games, try Haze or Wraith.</li>
          <li>To have strong team-wide utility, try Dynamo or Kelvin.</li>
          <li>For cooldown-gated burst damage, try out Lash or Bebop.</li>
        </ul>

        <h4>What do I buy?</h4>
        <p>
          Firstly, enter a match or the sandbox mode from the main menu. If
          you’re looking for a specific stat on an item, the search bar at the
          top right will filter by the stat you type in. If you’re unsure what
          items to get entirely, the “Browse Builds” button just beneath the
          search bar will list player-made builds that can recommend items and
          which abilities to level.
        </p>

        <h4>How do I play my hero?</h4>
        <p>
          The best way to learn to play is to simply play the game. The
          second-best way to learn your hero is to learn from someone who
          already knows how to play them. Your options for this are to either:
        </p>
        <ul>
          <li>
            Use the “Watch” option from the main menu to watch live matches or
            demos. The “Featured Matches” page is sorted to show higher level
            games.
          </li>
          <li>
            Read or watch a guide for your hero, some of which can be found in
            the #guides channel of the Discord.
          </li>
        </ul>

        <h4>How do levels and ability points work?</h4>
        <p>
          Your level is determined by the total souls you have accrued
          throughout a match, also referred to as your Net Worth. Progress to
          your next level is represented in game by a flask to the left of your
          health bar.
        </p>
        <p>
          Each time you level up in a match you will receive either an Ability
          Unlock, Ability Points, or a Power Increase.
        </p>
        <ul>
          <li>
            Ability Unlocks allow you to pick a new ability to unlock, and do
            not cost any AP.
          </li>
          <li>
            Ability Points (AP) allow you to increase the power of an ability
            you already have unlocked. They can additionally be gained from
            objectives like the Soul Urn and enemy walkers.
          </li>
          <li>
            Power Increases will give you a set of stat boosts, including Base
            Bullet Damage, Base Melee Damage, and Base Health. The amount of
            each differs per hero. For some heroes, Power Increases will also
            offer additional bonuses like Bullet Resist and Weapon Range for
            Bebop.
          </li>
        </ul>
        <p>
          To find out exactly what stats your hero will receive per power
          increase, as well as other relevant information, select “Heroes” on
          the main menu and pick who you’d like to learn about.
        </p>

        <h4>How do items work?</h4>
        <p>
          Every item you purchase will come with stats unique to it, as well as
          a bonus based on the category the item is from that increases the
          higher tier the item is.
        </p>
        <ul>
          <li>Weapon items give %weapon damage increasing with tier</li>
          <li>
            Spirit items give a flat bonus to spirit power increasing with tier
          </li>
          <li>Vitality items give %health increasing with tier</li>
        </ul>
        <p>
          Some items have active effects that you must assign to an input, these
          can be reordered by clicking and dragging the icons.
        </p>

        <h4>What are flex slots? How do I get them?</h4>
        <p>
          Items in your inventory are sorted into blocks by category. The
          furthest left block is orange and is for weapon items, the second from
          the left is green and is for vitality items, the second from the right
          is purple and is for spirit items, and the final block on the far
          right are flex slots. Flex slots differ from the other categories in
          that you may buy ANY item from the other categories for them, so long
          as the item fits the slot’s tier.
        </p>

        <h4>Can I play solo, or do I need a team?</h4>
        <p>
          You can play solo or with a team; the game is very flexible in this
          regard. It will automatically assign players to teams with other
          players when the match starts, if you join solo.
        </p>

        <h4>How do I know if I’m winning or losing?</h4>
        <p>
          The game will automatically show the relative net worth of each team
          and the match progress in the top left of the screen, as well as the
          timer until the next match phase begins. This way you can always know
          how well you are doing and whether you need to adapt your strategy.
        </p>

        <h4>Can I sell items?</h4>
        <p>
          No, items cannot be sold. Once you’ve purchased an item it is
          permanently in your inventory until the match ends.
        </p>

        <h4>Can I refund items?</h4>
        <p>No, items cannot be refunded.</p>

        <h4>How do I use my abilities?</h4>
        <p>
          Abilities are used by pressing their assigned key bind (default is
          1-4) or clicking their icon in the ability bar. You can reassign
          ability key binds in the settings menu or from the in-game console
          using the “bind” command.
        </p>

        <h4>What are the abilities for?</h4>
        <p>
          Abilities come in many forms. Some give you increased damage, some
          heal you or your allies, and some grant utility such as additional
          movement speed or crowd control effects. Abilities are meant to
          complement your hero’s base stats and allow you to adapt to different
          situations in a match.
        </p>

        <h4>Can I change my abilities mid-match?</h4>
        <p>
          Abilities can be changed if you choose to unlock new ones by leveling
          up. Some items and heroes also offer the ability to reset your ability
          selections at a cost.
        </p>

        <h4>Can I see how many enemies are in a match?</h4>
        <p>
          You can see the number of enemies remaining on the map at all times.
          The HUD in the bottom right corner of the screen displays how many
          enemies are still in play and their locations.
        </p>

        <h4>What is the purpose of objectives?</h4>
        <p>
          Objectives are used to either gain temporary buffs or score additional
          points. Objectives also provide additional resources like souls and
          items to assist you in your battle against the enemy team.
        </p>

        <h4>What happens if I die?</h4>
        <p>
          If you die, you will respawn at your base after a short delay. During
          this time, you are free to spectate the ongoing match or look at your
          match history.
        </p>

        <h4>How do I win a match?</h4>
        <p>
          You win a match by completing the primary objective of the match. This
          may include destroying a core, completing a series of objectives, or
          accumulating the highest net worth before time runs out.
        </p>

        <h4>How long does a match last?</h4>
        <p>
          Matches typically last around 30 minutes but can be longer or shorter
          depending on the game mode and player actions.
        </p>

        <h4>What is the highest level I can reach?</h4>
        <p>
          The maximum level you can reach in a match is 30. After reaching level
          30, you can still continue to gain additional stats through items and
          power increases.
        </p>

        <h4>How do I get better at the game?</h4>
        <p>
          The best way to improve is to practice and learn from other players.
          Watching replays, reading guides, and playing regularly will help you
          understand the game’s mechanics and strategies better.
        </p>

        <h3>4. Glossary of Common Terms</h3>
        <h4>What is “Net Worth”?</h4>
        <p>
          Net Worth is a measure of your hero’s overall value in the game based
          on items and power increases. It helps determine your relative
          strength compared to the enemy team.
        </p>

        <h4>What is “Souls”?</h4>
        <p>
          Souls are a resource you collect throughout the match. They are used
          to level up your hero and gain additional abilities or power
          increases.
        </p>

        <h4>What are “Flex Slots”?</h4>
        <p>
          Flex Slots are inventory slots that can hold items from any category,
          unlike the other slots which are restricted to specific item types.
        </p>

        <h4>What does “CC” stand for?</h4>
        <p>
          CC stands for “Crowd Control,” which refers to abilities or effects
          that hinder or control the movement or actions of enemies.
        </p>

        <h4>What is a “Buff”?</h4>
        <p>
          A Buff is an effect that increases your hero’s stats or abilities
          temporarily.
        </p>

        <h4>What is a “Debuff”?</h4>
        <p>
          A Debuff is an effect that decreases your hero’s stats or abilities
          temporarily.
        </p>

        <h3>5. Details / Stats / Trivia</h3>
        <h4>What is the purpose of the detailed stats?</h4>
        <p>
          Detailed stats provide insights into your performance and
          contributions during a match. They can help you identify areas for
          improvement and track your progress over time.
        </p>

        <h4>Where can I find trivia about the game?</h4>
        <p>
          Trivia about the game can be found in the game’s official forums and
          community discussions. Additionally, some trivia may be included in
          the game’s lore and background sections.
        </p>
      </div>
      <div>
        <h3>1. General Questions</h3>
        <h4>What is the objective of the game?</h4>
        <p>
          The primary objective is to destroy the enemy's core while defending
          your own. You accomplish this by battling enemies, capturing
          objectives, and collecting resources to strengthen your hero and team.
        </p>

        <h4>How does crowd control interact with channeled abilities?</h4>
        <p>
          Channeled abilities require the user to stay immobile for the duration
          of their channel and prevent the usage of other items or abilities
          until they finish or are canceled. Common examples include Mo and
          Krill’s or Seven’s ultimate abilities. To cancel these abilities, you
          must use an item or ability with the interrupt keyword in its
          description, such as those that stun, or the Curse item.
          Displacements, sleeps, and silences do not interrupt channeled
          abilities.
        </p>

        <h4>Can I play solo, or do I need a team?</h4>
        <p>
          You can play solo or with a team; the game is very flexible in this
          regard. It will automatically assign players to teams with other
          players when the match starts if you join solo.
        </p>

        <h4>How do I know if I’m winning or losing?</h4>
        <p>
          The game will automatically show the relative net worth of each team
          and the match progress in the top left of the screen, as well as the
          timer until the next match phase begins. This way you can always know
          how well you are doing and whether you need to adapt your strategy.
        </p>

        <h4>Can I sell items?</h4>
        <p>
          No, items cannot be sold. Once you’ve purchased an item it is
          permanently in your inventory until the match ends.
        </p>

        <h4>Can I refund items?</h4>
        <p>No, items cannot be refunded.</p>

        <h4>How do I use my abilities?</h4>
        <p>
          Abilities are used by pressing their assigned key bind (default is
          1-4) or clicking their icon in the ability bar. You can reassign
          ability key binds in the settings menu or from the in-game console
          using the “bind” command.
        </p>

        <h4>What are the abilities for?</h4>
        <p>
          Abilities come in many forms. Some give you increased damage, some
          heal you or your allies, and some grant utility such as additional
          movement speed or crowd control effects. Abilities are meant to
          complement your hero’s base stats and allow you to adapt to different
          situations in a match.
        </p>

        <h4>Can I change my abilities mid-match?</h4>
        <p>
          Abilities can be changed if you choose to unlock new ones by leveling
          up. Some items and heroes also offer the ability to reset your ability
          selections at a cost.
        </p>

        <h4>Can I see how many enemies are in a match?</h4>
        <p>
          You can see the number of enemies remaining on the map at all times.
          The HUD in the bottom right corner of the screen displays how many
          enemies are still in play and their locations.
        </p>

        <h4>What is the purpose of objectives?</h4>
        <p>
          Objectives are used to either gain temporary buffs or score additional
          points. Objectives also provide additional resources like souls and
          items to assist you in your battle against the enemy team.
        </p>

        <h4>What happens if I die?</h4>
        <p>
          If you die, you will respawn at your base after a short delay. During
          this time, you are free to spectate the ongoing match or look at your
          match history.
        </p>

        <h4>How do I win a match?</h4>
        <p>
          You win a match by completing the primary objective of the match. This
          may include destroying a core, completing a series of objectives, or
          accumulating the highest net worth before time runs out.
        </p>

        <h4>How long does a match last?</h4>
        <p>
          Matches typically last around 30 minutes but can be longer or shorter
          depending on the game mode and player actions.
        </p>

        <h4>What is the highest level I can reach?</h4>
        <p>
          The maximum level you can reach in a match is 30. After reaching level
          30, you can still continue to gain additional stats through items and
          power increases.
        </p>

        <h4>How do I get better at the game?</h4>
        <p>
          The best way to improve is to practice and learn from other players.
          Watching replays, reading guides, and playing regularly will help you
          understand the game’s mechanics and strategies better.
        </p>

        <h3>2. Laning</h3>
        <h4>What is laning?</h4>
        <p>
          At the beginning of the game, you are assigned to a transit line and
          taken down a lane to fight an opponent; what follows is laning.
        </p>

        <h4>What are the lanes called?</h4>
        <p>
          Lanes are referred to by the color visible both on the map and on the
          transit lines themselves.
        </p>

        <h4>How are lanes assigned?</h4>
        <p>
          Lanes are assigned by the matchmaker and determined by the skill
          levels of the players involved. Hero composition isn’t taken into
          account. If you are in a duo queue with a similarly skilled player,
          you are more likely to be in a duo lane.
        </p>

        <h4>Why do I never have enough ammunition to last hit?</h4>
        <p>
          You are either shooting at the enemy player or their troopers too
          much. To secure souls, you need only shoot the trooper once as its
          health runs out (indicated by it flashing brightly) and then shoot the
          soul orb it releases once afterwards. If you melee a trooper just
          before it dies, you will secure the last hit AND the soul orb,
          preventing the enemy from denying you and costing no ammo.
        </p>

        <h4>When do I buy items?</h4>
        <p>
          You should be purchasing 500 soul items as soon as you can afford
          them. They are highly efficient stat/cost wise and are never a wasted
          slot, especially early in the game. Keep in mind that the more items
          you have over the enemy, the stronger you are than them. If you get a
          lead while laning, buying some 1.25k items is a good idea to snowball.
          If you run out of slots later in the game, simply sell your cheaper
          items to purchase more powerful ones.
        </p>

        <h4>How do souls work in a duo lane?</h4>
        <p>
          Before the 10-minute mark, souls will be duplicated for full value
          between two players in the vicinity of the lane. Any more than two
          players in a lane will cause the souls to instead be split that number
          of ways for players other than the one last hitting, effectively
          reducing the total income of ALL other players involved. After 10
          minutes, souls are always split regardless of player count.
        </p>

        <h4>What do I do after I take the enemy guardian in my lane?</h4>
        <p>
          Look to group up with your team and gank for them, do map objectives
          like Urn and Mid-Boss, and if you can do none of the above then keep
          your trooper waves pushed out and farm jungle camps to keep earning
          souls.
        </p>

        <h4>What am I meant to do if I feel like I can't win my lane?</h4>
        <p>
          Focus on not dying, playing far back where you can safely use cover,
          and focus on denying enemy soul orbs while securing only the safest
          last hits for yourself. Items with regen such as Extra Regen,
          Restorative Shot, and Healing Rite can keep you alive. If your
          guardian looks like it is going to die, stay much further back in the
          lane so you don’t die immediately afterwards and try to deny the soul
          orbs it releases to lessen the enemy’s advantage.
        </p>

        <h3>3. Map / Objectives</h3>
        <h4>What does each icon on the map represent?</h4>
        <p>
          Icons on the map represent various elements like objectives, shops,
          and points of interest. For example, objectives like Urn and Mid-Boss
          are marked distinctly to highlight their locations.
        </p>

        <h4>Where do I buy items?</h4>
        <p>
          Shops are available to buy items inside your base, as well as in each
          lane. In addition to this, there are two secret shops near mid. The
          difference between these kinds of shops is that:
        </p>
        <ul>
          <li>
            Lane shops will close when the lane guardian in their lane dies.
          </li>
          <li>Base and secret shops will stay open all game.</li>
        </ul>

        <h4>What’s a Cosmic Veil?</h4>
        <p>
          At various places around the map, you will come across Cosmic Veils.
          These either look gray or tinted with a rainbow shimmer. These will
          never block movement but only allow sight one way. If you are on the
          gray side, you cannot see to the other side of the barrier. If you are
          on the rainbow side, you can see through the barrier as if nothing was
          there.
        </p>

        <h4>What’s a bounce pad?</h4>
        <p>A small orange pad on the ground that will propel you upward.</p>

        <h4>What are the temporary buffs?</h4>
        <p>
          Temporary buffs are gained by capturing map objectives like Urn or
          Mid-Boss. These buffs vary but generally provide bonuses like
          increased damage, health, or other enhancements that can give you an
          edge in combat.
        </p>

        <h4>When should I focus on objectives?</h4>
        <p>
          Objectives should be focused on when you have a significant advantage
          in souls or enemy deaths, and when you can safely secure them without
          risking a counterattack. Regularly check for opportunities to secure
          buffs and map objectives to gain an advantage over the enemy team.
        </p>

        <h4>How do I use the teleporters on the map?</h4>
        <p>
          Teleporters can be activated by standing on their designated pads.
          They will transport you to predetermined locations on the map. Use
          them strategically to quickly traverse the map or escape dangerous
          situations.
        </p>

        <h4>What does the timer in the top right of the screen indicate?</h4>
        <p>
          The timer in the top right indicates the time remaining until the next
          match phase or objective spawn. It helps you keep track of when new
          opportunities or threats will arise in the game.
        </p>

        <h4>What happens when the core is destroyed?</h4>
        <p>
          When the core is destroyed, the match ends, and the team that
          destroyed the core is declared the winner. The game will then display
          the match results and statistics.
        </p>

        <h3>4. Matchmaking / Account</h3>
        <h4>How does matchmaking work?</h4>
        <p>
          Matchmaking is based on player skill and experience, as well as the
          current rank or tier of the players involved. The system aims to
          create balanced matches by pairing players of similar skill levels.
        </p>

        <h4>Can I change my hero mid-match?</h4>
        <p>
          No, you cannot change your hero mid-match. You must select your hero
          before the match starts and play with that hero throughout the game.
        </p>

        <h4>How do I report players?</h4>
        <p>
          Players can be reported through the in-game menu by selecting the
          offending player and choosing the appropriate reason for the report.
          Reports are reviewed by the game’s moderation team to ensure fair
          play.
        </p>

        <h4>Can I play with friends?</h4>
        <p>
          Yes, you can play with friends by forming a party before the match
          starts. Parties can be created from the main menu and invite friends
          to join your team.
        </p>

        <h4>How do I update my profile information?</h4>
        <p>
          Profile information can be updated through the account settings menu
          in the game or on the official website. Changes can include updating
          your username, avatar, and other account details.
        </p>

        <h4>What should I do if I encounter a bug?</h4>
        <p>
          If you encounter a bug, you should report it through the game’s
          support or feedback system. Provide as much detail as possible,
          including steps to reproduce the issue and any relevant screenshots or
          error messages.
        </p>

        <h4>Can I change my username?</h4>
        <p>
          Username changes are typically not allowed, but exceptions may be made
          by contacting customer support. Check the game’s policy on username
          changes for more details.
        </p>
      </div>
    </>
  );
};

export default MasteringTheBasicsGuide;
