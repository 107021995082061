// src/components/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Create this CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <Link to="/get-deadlock-invite">Get Deadlock Invite</Link>
          <Link to="/discord">Discord</Link>
          <Link to="/about-us">About Us</Link>
        </div>
        <div className="footer-copyright">
          <p>&copy; 2024 Deadlock Tracker. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
