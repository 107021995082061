import React, { useState } from "react";
import HeroTable from "../components/HeroTable";
import "./HeroesPage.css"; // Import the CSS for styling

import HeroesData from "../components/HeroesData";
// Example hero data structured by patches
const heroes = HeroesData;

function HeroesPage() {
  const [selectedPatch, setSelectedPatch] = useState("Patch 1.2");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectPatch = (patch) => {
    setSelectedPatch(patch);
    setIsDropdownOpen(false);
  };

  return (
    <div className="heroes-page">
      <h1>Hero Statistics</h1>
      <div className="dropdown">
        <button className="dropdown-select" onClick={toggleDropdown}>
          {selectedPatch}
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            {Object.keys(heroes).map((patch) => (
              <a
                key={patch}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  selectPatch(patch);
                }}
              >
                {patch}
              </a>
            ))}
          </div>
        )}
      </div>
      <HeroTable heroes={heroes[selectedPatch]} />
    </div>
  );
}

export default HeroesPage;
