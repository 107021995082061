import { SpinnerCircular } from "spinners-react";
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="spin-center">
      <SpinnerCircular />
    </div>
  );
};

export default LoadingSpinner;
