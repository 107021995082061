import React, { useState } from "react";
import ItemTable from "./ItemTable";
import "./Tabs.css"; // Add CSS for tabs styling
import itemDataWithPaths from "./itemDataWithPaths";

const Tabs = () => {
  const [selectedTier, setSelectedTier] = useState("Tier 1");
  console.log(itemDataWithPaths);
  return (
    <div>
      <div className="tabs">
        {Object.keys(itemDataWithPaths).map((tier) => (
          <button
            key={tier}
            className={`tab-button ${selectedTier === tier ? "active" : ""}`}
            onClick={() => setSelectedTier(tier)}
          >
            {tier}
          </button>
        ))}
      </div>
      <ItemTable items={itemDataWithPaths[selectedTier]} />
    </div>
  );
};

export default Tabs;
