import React from "react";
import { IconTwitch, IconYouTube, IconTwitter } from "../icons";

const AuthorProfiles = ({
  twitchProfile,
  setTwitchProfile,
  youtubeProfile,
  setYoutubeProfile,
  twitterProfile,
  setTwitterProfile,
  isVisible,
  toggleVisibility,
  triangleClass,
}) => {
  return (
    <div className="form-section author-profiles-section">
      <div className="item-build-header" onClick={toggleVisibility}>
        <span>Socials</span>
        <div className={triangleClass} />
      </div>
      <div className={`item-build-content ${isVisible ? "visible" : "hidden"}`}>
        <div className="social-profiles">
          <div className="form-section">
            <label htmlFor="twitch">
              <IconTwitch /> Twitch
            </label>
            <input
              type="text"
              id="twitch"
              value={twitchProfile}
              onChange={(e) => setTwitchProfile(e.target.value)}
            />
          </div>

          <div className="form-section">
            <label htmlFor="youtube">
              <IconYouTube /> YouTube
            </label>
            <input
              type="text"
              id="youtube"
              value={youtubeProfile}
              onChange={(e) => setYoutubeProfile(e.target.value)}
            />
          </div>

          <div className="form-section">
            <label htmlFor="twitter">
              <IconTwitter /> Twitter
            </label>
            <input
              type="text"
              id="twitter"
              value={twitterProfile}
              onChange={(e) => setTwitterProfile(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorProfiles;
