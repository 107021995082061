import React from "react";
import "./ItemSelection.css";
import items from "./items";
import { useState } from "react";

const getItemCost = (path) => {
  const parts = path.split("/");
  return parts[parts.length - 1]; // Last part of the path is the cost
};

const ItemSelection = ({ selectedItems, setSelectedItems }) => {
  const [itemType, setItemType] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");

  const filteredItems = items.filter((item) => {
    if (itemType === "all") {
      return item.filename.toLowerCase().includes(searchQuery.toLowerCase());
    } else {
      return (
        item.path.includes(itemType) &&
        item.filename.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  });

  const toggleItemSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item)); // Deselect item
    } else {
      setSelectedItems([...selectedItems, item]); // Select item
    }
  };

  // Determine background color based on item path
  const getItemBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#fbab4d";
    } else if (path.includes("vitality")) {
      return "#87c81d";
    } else if (path.includes("spirit")) {
      return "#dd9cff";
    }
    return "transparent"; // Default if no match
  };

  // Determine background color based on item path
  const getItemCostBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#ca7a03";
    } else if (path.includes("vitality")) {
      return "#659819";
    } else if (path.includes("spirit")) {
      return "#8b55b4";
    }
    return "transparent"; // Default if no match
  };

  // Extract item name from the filename
  const getItemName = (filename) => {
    return filename.split("-").pop().replace(".png", "").replace(/_/g, " ");
  };

  return (
    <div className="item-selection-section">
      <div className="filter-options">
        <select value={itemType} onChange={(e) => setItemType(e.target.value)}>
          <option value="all">All</option>
          <option value="weapon">Weapon</option>
          <option value="vitality">Vitality</option>
          <option value="spirit">Spirit</option>
        </select>
        <input
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search items by name"
        />
      </div>
      <div className="items-grid">
        {filteredItems.map(({ path, filename }) => (
          <>
            <div
              key={`${path}/${filename}`}
              className={`item-icon-wrapper ${
                selectedItems.includes(`${path}/${filename}`) ? "selected" : ""
              }`}
              style={{
                backgroundColor: getItemBackgroundColor(path),
              }}
              onClick={() => toggleItemSelection(`${path}/${filename}`)}
            >
              <div
                className="item-cost"
                style={{
                  backgroundColor: getItemCostBackgroundColor(path),
                }}
              >
                <img
                  src={`/20px-Souls_icon.png`}
                  alt={getItemName(filename)}
                  className="souls"
                />
                {getItemCost(path)}
              </div>

              <img
                src={`${path}/${filename}`}
                alt={getItemName(filename)}
                className="item-icon"
              />

              <div className="item-name">{getItemName(filename)}</div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ItemSelection;
export { getItemCost };
