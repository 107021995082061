// src/components/FeaturedGuides.jsx
import React from "react";
import "./FeaturedGuides.css"; // Ensure this path is correct

const guides = [
  {
    id: 1,
    title: "Mastering the Basics",
    description: "A beginner's guide to understanding the core mechanics.",
    link: "/guides/mastering-the-basics",
  },
  {
    id: 2,
    title: "Advanced Strategies",
    description: "In-depth strategies for experienced players.",
    link: "/guides/advanced-strategies",
  },
  {
    id: 3,
    title: "Hero Guides",
    description: "Detailed guides for each hero.",
    link: "/guides/hero-guides",
  },
  // Add more guide objects as needed
];

const FeaturedGuides = () => {
  return (
    <div className="featured-guides">
      <h2>Featured Guides</h2>
      <ul>
        {guides.map((guide) => (
          <li key={guide.id}>
            <a href={guide.link} className="guide-link">
              <h3>{guide.title}</h3>
              <p>{guide.description}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeaturedGuides;
