import React from "react";
import "../pages/HeroDetailPage.css";

const WeaponStats = ({ stats }) => {
  return (
    <div className="weapon-stats">
      <h2 className="weapon-title">Weapon Stats</h2>
      <div className="weapon-stats-list">
        <ul>
          <li>
            <span>Clip Size: </span>
            <span className="font-semibold">{stats.clipSize}</span>
          </li>
          <li>
            <span>Bullet Damage: </span>
            <span className="font-semibold">{stats.bulletDamage}</span>
          </li>
          <li>
            <span>Fire Rate: </span>
            <span className="font-semibold">{stats.fireRate}</span>
          </li>
          <li>
            <span>DPS: </span>
            <span className="font-semibold">{stats.DPS}</span>
          </li>
          {/* Add more weapon stats as needed */}
        </ul>
      </div>
    </div>
  );
};

export default WeaponStats;
