import React, { useState } from "react";
import "./ItemTable.css";

const ItemTable = ({ items }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const sortedItems = [...items].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const minWinrate = Math.min(...sortedItems.map((item) => item.winrate));
  const maxWinrate = Math.max(...sortedItems.map((item) => item.winrate));
  const minPickrate = Math.min(...sortedItems.map((item) => item.pickrate));
  const maxPickrate = Math.max(...sortedItems.map((item) => item.pickrate));

  const getBarWidth = (value, min, max) => {
    const scaledValue = (value - min) / (max - min);
    return 10 + scaledValue ** 0.5 * 90; // Non-linear scaling
  };

  const getItemBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#fbab4d";
    } else if (path.includes("vitality")) {
      return "#87c81d";
    } else if (path.includes("spirit")) {
      return "#dd9cff";
    }
    return "transparent"; // Default if no match
  };

  const getItemCostBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#ca7a03";
    } else if (path.includes("vitality")) {
      return "#659819";
    } else if (path.includes("spirit")) {
      return "#8b55b4";
    }
    return "transparent"; // Default if no match
  };

  return (
    <table className="item-table">
      <thead>
        <tr>
          <th onClick={() => requestSort("name")}>Item</th>
          <th onClick={() => requestSort("type")}>Type</th>
          <th onClick={() => requestSort("winrate")}>Win Rate</th>
          <th onClick={() => requestSort("pickrate")}>Pick Rate</th>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((item) => (
          <tr key={item.id}>
            <td className="item-name-cell">
              <div
                className="table-item-icon-wrapper"
                style={{ backgroundColor: getItemBackgroundColor(item.path) }}
              >
                <div
                  className="table-item-cost"
                  style={{
                    backgroundColor: getItemCostBackgroundColor(item.path),
                  }}
                >
                  <img
                    src="/20px-Souls_icon.png"
                    alt="Souls"
                    className="table-souls"
                  />
                  {item.cost}
                </div>
                <img
                  src={item.path}
                  alt={item.filename}
                  className="table-item-icon"
                />
              </div>
              <span style={{ marginLeft: 10 }}>{item.name}</span>
            </td>
            <td>{item.type}</td>
            <td style={{ position: "relative" }}>
              <div className="winrate-bar-container">
                <div
                  className="winrate-bar"
                  style={{
                    backgroundColor: item.winrate > 50 ? "#3ebb8d" : "#e8432d",
                    width: `${getBarWidth(
                      item.winrate,
                      minWinrate,
                      maxWinrate
                    )}%`,
                  }}
                />
                <span className="winrate-text">{item.winrate}%</span>
              </div>
            </td>
            <td style={{ position: "relative" }}>
              <div className="winrate-bar-container">
                <div
                  className="pickrate-bar"
                  style={{
                    backgroundColor:
                      item.pickrate > 20
                        ? "#3ebb8d" // Green for high pickrate
                        : item.pickrate > 10
                        ? "#f7dc6f" // Yellow for medium pickrate
                        : "#e8432d", // Red for low pickrate
                    width: `${getBarWidth(
                      item.pickrate,
                      minPickrate,
                      maxPickrate
                    )}%`,
                  }}
                />
                <span className="pickrate-text">{item.pickrate}%</span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemTable;
