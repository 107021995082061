import React from "react";
import SearchPlayers from "../components/SearchPlayers";
import "./HomePage.css";
import TopWinrateHeroes from "../components/TopWinrateHeros";
import LowestWinrateHeroes from "../components/LowestWinrateHeros";
import HeroesData from "../components/HeroesData";
import FeaturedGuides from "../components/FeaturedGuides";

const HomePage = () => {
  // Choose the patch you want to display
  const selectedPatch = "Patch 1.2";
  const heroes = HeroesData[selectedPatch];

  return (
    <>
      <div className="home-container">
        <h1>Welcome to Deadlock Guides</h1>
        <p>Create guides, track stats, and explore hero analytics.</p>
        <SearchPlayers />
      </div>
      <div className="hero-winrate-container">
        <TopWinrateHeroes heroes={heroes} />
        <LowestWinrateHeroes heroes={heroes} />
      </div>
      <div className="guides-container">
        <FeaturedGuides /> {/* Add the FeaturedGuides component here */}
      </div>
    </>
  );
};

export default HomePage;
