import { useEffect, useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Header.css";
import { UserContext } from "../UserContext";

function Header() {
  const { user, setUser } = useContext(UserContext);

  // Optional: Handle user authentication status
  useEffect(() => {
    if (user) {
      console.log("User authenticated:", user);
    } else {
      console.log("User not authenticated");
    }
  }, [user]);

  const handleLogin = () => {
    window.location.href =
      "https://deadlock-stat-tracker-api.vercel.app/api/auth/steam";
  };

  const handleLogout = () => {
    window.location.href =
      "https://deadlock-stat-tracker-api.vercel.app/api/auth/logout";
  };

  return (
    <>
      <header className="header">
        <Link to="/" className="header-title">
          <img src="/dlg.png" alt="Deadlock Tracker" width="250" height="40" />
        </Link>
        <nav className="nav">
          <NavLink
            to="/heroes"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Heroes
          </NavLink>
          <NavLink
            to="/items"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Items
          </NavLink>
          <NavLink
            to="/leaderboards"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Leaderboards
          </NavLink>
          <NavLink
            to="/guides"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Guides
          </NavLink>

          {user ? (
            <>
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  isActive ? "nav-link active-link" : "nav-link"
                }
              >
                Profile
              </NavLink>
              <NavLink to="/" className="nav-link" onClick={handleLogout}>
                Log Out
              </NavLink>
            </>
          ) : (
            <button className="nav-link" onClick={handleLogin}>
              Login
            </button>
          )}
        </nav>
      </header>
    </>
  );
}

export default Header;
