// IvyGuide.jsx
import React from "react";

const TaimouWraithGuide = () => {
  return (
    <div className="taimou-wraith-guide">
      <h1>Taimou's Guide for Wraith</h1>
      <p>
        Hello I'm Taimou, this is my current build for Wraith and you'll
        probably see me in the EU featured games playing this build for the next
        few days and tweaking it slightly :--)
      </p>

      <p>
        You can find the guide items from the curiosity shop under the name:{" "}
        <strong>"Taimou Wraith NEW"</strong>
      </p>

      <h2>Skill Order in Lane</h2>
      <p>
        <strong>Aggro:</strong> 1 3 2 4
        <br />
        <strong>Defensive:</strong> 1 2 3 4 (if you feel like there's even a
        slight chance that you could die)
      </p>

      <h2>Skill Up Order</h2>
      <p>
        Max 2 first ALWAYS, this is literally the bread and butter of Wraith. It
        lets you move around the map more efficiently, escape ganks while you
        splitpush, have stronger all-in engagements, chase enemies, farm faster,
        etc.
      </p>
      <p>Max 4 second, 3 third, and 1 last.</p>

      <h2>Gameplay Strategy</h2>
      <p>
        Your goal is to always WIN your lane and not just go even. Wraith
        doesn't have the best kill potential in a solo lane against some
        opponents but there's almost always an answer to everything by itemizing
        yourself correctly and just being able to utilize the melee, movement
        system, and your aim to your advantage.
      </p>
      <p>
        I want to add clips of cool tricks and all-ins later. (Please give
        suggestions on how to clip things efficiently.)
      </p>

      <h2>Your Strengths</h2>
      <p>
        Your biggest strength is split-pushing. There literally isn't a better
        1v1 character in the game currently that, if left unchecked, will
        destroy your base in a minute herself.
      </p>
      <p>
        Your gameplay is very boring, simple but efficient at winning games:
        Shove your lane, hit boxes, and shoot jungle creeps.
      </p>
      <p>
        You aren't a strong teamfighter until very late into the game. Wraith
        deals very low damage in fights until around 20k net worth, and even
        then it's mediocre compared to other carry characters like Seven and
        Haze.
      </p>
      <p>
        Your forte is finding picks, pushing uncontested lanes, and finding 1v1s
        in the enemy jungle and then hiding until the coast is clear to do it
        all again.
      </p>
      <p>
        At around 40-45k net worth when you really get online and can pretty
        much 1v11, is when you should join your team for fights if you haven't
        already won the game by split-pushing. This is when you'll have
        Unstoppable, Shadow Weave, and Inhibitor.
      </p>

      <h2>Item Build Notes</h2>
      <p>
        The 6500 Weapon items are a bait. You should only buy Silencer as one of
        your core 6500 items if you need it for certain 1v1s or when you want to
        join big teamfights.
      </p>
      <p>
        Example build for when you're pretty much ready to look for picks solo
        and split-push the enemy lanes.
      </p>
    </div>
  );
};

export default TaimouWraithGuide;
