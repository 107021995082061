import React from "react";
import { Link } from "react-router-dom";
import "./LowestWinrateHeros.css"; // Ensure this path is correct

const LowestWinrateHeroes = ({ heroes }) => {
  // Sort heroes by winrate in ascending order and take the bottom 5
  const bottomHeroes = [...heroes]
    .sort((a, b) => a.winrate - b.winrate) // Ascending order
    .slice(0, 5); // Get the bottom 5

  const decodedHeroName = (heroName) =>
    heroName.replace(/\s+/g, "_").replace("&", "and").toLowerCase();

  return (
    <div className="lowest-winrate-heroes">
      <h2>
        Worst Heroes <span className="latest-patch">(Latest Patch)</span>
      </h2>
      <ul>
        {bottomHeroes.map((hero) => (
          <li key={hero.id}>
            <img
              src={`/charicon/${decodedHeroName(hero.name)}.webp`}
              alt={hero.name}
              className="hero-icon"
            />
            <span>
              <Link to={`/heroes/${hero.name}`} className="hero-link">
                {hero.name}
              </Link>{" "}
              - <span className="winrate">{hero.winrate}%</span>
            </span>
          </li>
        ))}
      </ul>
      <hr className="divider" />
      <div className="see-all-heroes">
        <Link to="/heroes" className="see-all-link">
          See all Heroes
        </Link>
      </div>
    </div>
  );
};

export default LowestWinrateHeroes;
