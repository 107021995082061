import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./CreateGuidePage.css";
import ItemSelection from "../components/ItemSelection"; // Import the new ItemSelection component
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import Quill from "quill"; // Import Quill
import AuthorProfiles from "../components/AuthorProfiles";

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
const CreateGuidePage = () => {
  const [title, setTitle] = useState("");
  const [hero, setHero] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null); // State to capture errors
  const [username, setUsername] = useState(""); // State to capture the username
  const [category, setCategory] = useState("Hero"); // New state for category
  const [selectedItems, setSelectedItems] = useState([]); // Track selected items for item build
  const navigate = useNavigate();
  const [isItemBuildVisible, setIsItemBuildVisible] = useState(true);
  const [triangleClass, setTriangleClass] = useState("triangle");
  const [steamIdState, setSteamIdState] = useState("");
  const [twitchProfile, setTwitchProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");
  const [isAuthorProfilesVisible, setIsAuthorProfilesVisible] = useState(true);
  const [authorProfilesTriangleClass, setAuthorProfilesTriangleClass] =
    useState("triangle");
  const [chapters, setChapters] = useState([{ title: "", content: "" }]);
  const toggleAuthorProfilesVisibility = () => {
    setIsAuthorProfilesVisible(!isAuthorProfilesVisible);
    setAuthorProfilesTriangleClass(
      authorProfilesTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
  };
  const imageHandler = (image) => {
    const quill = ReactQuill.Quill;
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", image);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "https://deadlock-stat-tracker-api.vercel.app/api/upload-image",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        imageHandler(data.imageUrl);
      } else {
        console.error("Failed to upload image");
      }
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  const heroes = [
    "Abrams",
    "Bebop",
    "Dynamo",
    "Grey Talon",
    "Haze",
    "Infernus",
    "Ivy",
    "Kelvin",
    "Lady Geist",
    "Lash",
    "McGinnis",
    "Mo & Krill",
    "Paradox",
    "Pocket",
    "Seven",
    "Shiv",
    "Vindicta",
    "Viscous",
    "Warden",
    "Wraith",
    "Yamato",
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/current-user",
          {
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUsername(data?.username || "");
          setSteamIdState(data?.steamId || "");
        } else {
          console.error("Failed to fetch current user");
        }
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newGuide = {
      title,
      hero,
      username,
      category,
      items: selectedItems,
      authorSteamId: steamIdState,
      chapters,
      socialLinks: {
        twitch: twitchProfile || "",
        youtube: youtubeProfile || "",
        twitter: twitterProfile || "",
      },
    };

    try {
      const response = await fetch(
        "https://deadlock-stat-tracker-api.vercel.app/api/guides",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newGuide),
        }
      );

      if (response.ok) {
        navigate("/guides");
      } else {
        const data = await response.json();
        setError(data.message || "An error occurred while saving the guide.");
      }
    } catch (err) {
      console.error("Fetch error:", err);
      setError("An error occurred while saving the guide.");
    }
  };

  const toggleItemBuildVisibility = () => {
    setIsItemBuildVisible(!isItemBuildVisible);
    setTriangleClass(
      triangleClass === "triangle" ? "triangle triangle-up" : "triangle"
    );
  };

  return (
    <div className="create-guide-page">
      <h1>Create a New Guide</h1>
      <div className="main-content">
        {error && <p className="error-message">{error}</p>}{" "}
        <div className="main-content"></div>
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <label htmlFor="title">Guide Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="form-section">
            <label htmlFor="hero">Hero:</label>
            <select
              id="hero"
              value={hero}
              onChange={(e) => setHero(e.target.value)}
              required
            >
              <option value="">Select a Hero</option>
              {heroes.map((heroName, index) => (
                <option key={index} value={heroName}>
                  {heroName}
                </option>
              ))}
            </select>
          </div>

          <div className="form-section">
            <label htmlFor="category">Category:</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="Hero">Hero</option>
              <option value="General">General</option>
            </select>
          </div>
          <AuthorProfiles
            twitchProfile={twitchProfile}
            setTwitchProfile={setTwitchProfile}
            youtubeProfile={youtubeProfile}
            setYoutubeProfile={setYoutubeProfile}
            twitterProfile={twitterProfile}
            setTwitterProfile={setTwitterProfile}
            isVisible={isAuthorProfilesVisible}
            toggleVisibility={toggleAuthorProfilesVisibility}
            triangleClass={authorProfilesTriangleClass}
          >
            <input
              type="text"
              value={twitchProfile}
              onChange={(e) => setTwitchProfile(e.target.value)}
              placeholder="Twitch Profile"
            />
            <input
              type="text"
              value={youtubeProfile}
              onChange={(e) => setYoutubeProfile(e.target.value)}
              placeholder="YouTube Profile"
            />
            <input
              type="text"
              value={twitterProfile}
              onChange={(e) => setTwitterProfile(e.target.value)}
              placeholder="Twitter Profile"
            />
          </AuthorProfiles>

          <div className="form-section">
            <label htmlFor="chapters">Chapters:</label>
            <button
              onClick={() =>
                setChapters([...chapters, { title: "", content: "" }])
              }
            >
              Add Chapter
            </button>
            {chapters.map((chapter, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={chapter.title}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].title = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  placeholder="Chapter Title"
                />
                <ReactQuill
                  value={chapter.content}
                  onChange={(content) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].content = content;
                    setChapters(updatedChapters);
                  }}
                  required
                  modules={CreateGuidePage.modules}
                  formats={CreateGuidePage.formats}
                />
                <button
                  onClick={() => {
                    const updatedChapters = [...chapters];
                    updatedChapters.splice(index, 1);
                    setChapters(updatedChapters);
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <div
            style={{ backgroundColor: "#142034" }}
            className="form-section item-build-section"
          >
            <div
              className="item-build-header"
              onClick={toggleItemBuildVisibility}
            >
              <span>Item Build</span>
              <div className={triangleClass} />
            </div>
            <div
              className={`item-build-content ${
                isItemBuildVisible ? "visible" : "hidden"
              }`}
            >
              <ItemSelection
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                className="item-selection-container"
              />
            </div>
          </div>

          <button type="submit">Publish</button>
        </form>
      </div>
    </div>
  );
};

CreateGuidePage.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    ["link"],
    ["image"],
    [{ align: [] }],
    ["clean"],
  ],
};

CreateGuidePage.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "align",
];

export default CreateGuidePage;
