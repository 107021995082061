// src/pages/NewsPage.js
import React from "react";

function LeaderboardsPage() {
  return (
    <div>
      <h1>Global Ranking</h1>
      <p>Coming soon!</p>
    </div>
  );
}

export default LeaderboardsPage;
