import React from "react";
import { Link } from "react-router-dom";
import "./TopWinrateHeros.css"; // Make sure this path is correct

const TopWinrateHeroes = ({ heroes }) => {
  // Sort heroes by winrate in descending order and take the top 5
  const topHeroes = [...heroes]
    .sort((a, b) => b.winrate - a.winrate)
    .slice(0, 5);
  const decodedHeroName = (heroName) =>
    heroName.replace(/\s+/g, "_").replace("&", "and").toLowerCase();

  return (
    <div className="top-winrate-heroes">
      <h2>
        Best Heroes <span className="latest-patch">(Latest Patch)</span>
      </h2>
      <ul>
        {topHeroes.map((hero) => (
          <li key={hero.id}>
            <img
              src={`/charicon/${decodedHeroName(hero.name)}.webp`}
              alt={hero.name}
              className="hero-icon"
            />
            <span>
              <Link to={`/heroes/${hero.name}`} className="hero-link">
                {hero.name}
              </Link>{" "}
              - <span className="winrate">{hero.winrate}%</span>
            </span>
          </li>
        ))}
      </ul>
      <hr className="divider" />
      <div className="see-all-heroes">
        <Link to="/heroes" className="see-all-link">
          See all Heroes
        </Link>
      </div>
    </div>
  );
};

export default TopWinrateHeroes;
