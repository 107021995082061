// src/App.jsx
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GuidesProvider } from "./GuidesContext";
import { UserProvider } from "./UserContext";

import Header from "./components/Header";
import AnnouncementBar from "./components/AnnouncementBar";
import Footer from "./components/Footer"; // Import Footer
import HomePage from "./pages/HomePage";
import NewsPage from "./pages/NewsPage";
import HeroesPage from "./pages/HeroesPage";
import ItemsPage from "./pages/ItemsPage";
import LeaderboardsPage from "./pages/LeaderboardsPage";
import GuidesPage from "./pages/GuidesPage";
import HeroDetailPage from "./pages/HeroDetailPage";
import CreateGuidePage from "./pages/CreateGuidePage";
import EditGuidePage from "./pages/EditGuidePage";
import GuideDetailPage from "./components/GuideDetailPage";
import ProfilePage from "./pages/ProfilePage";
import MasteringTheBasicsGuide from "./pages/MasteringTheBasicsGuide";
import "./App.css";
import IvyGuide from "./pages/IvyGuide";
import TaimouWraithGuide from "./pages/TaimouWraithGuide";

function App() {
  return (
    <UserProvider>
      <GuidesProvider>
        <Router>
          <div className="header-container">
            <Header />
          </div>
          <AnnouncementBar />
          <div className="container">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/heroes" element={<HeroesPage />} />
              <Route path="/heroes/:heroName" element={<HeroDetailPage />} />
              <Route path="/items" element={<ItemsPage />} />
              <Route path="/leaderboards" element={<LeaderboardsPage />} />
              <Route path="/guides" element={<GuidesPage />} />
              <Route path="/create-guide" element={<CreateGuidePage />} />
              <Route path="/guides/ivy-guide" element={<IvyGuide />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route
                path="/guides/mastering-the-basics"
                element={<MasteringTheBasicsGuide />}
              />
              <Route
                path="/guides/taimous-wraith-guide"
                element={<TaimouWraithGuide />}
              />
              <Route path="/guides/:guideSlug" element={<GuideDetailPage />} />
              <Route
                path="/guides/:guideSlug/edit"
                element={<EditGuidePage />}
              />
            </Routes>
          </div>
          <Footer /> {/* Add Footer here */}
        </Router>
      </GuidesProvider>
    </UserProvider>
  );
}

export default App;
