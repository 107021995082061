import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./ProfilePage.css";

const ProfilePage = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/current-user",
          {
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json(); // Parse the response JSON
          console.log(data); // Log the data after parsing

          // Update avatar URL to use high resolution
          data.avatar = data.avatar.replace(".jpg", "_full.jpg");
          setUser(data);
        } else {
          console.error("Response not OK", response.status);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  // Sample data for the chart showing ELO ratings over time
  const chartData = [
    { name: "Jan", ELO: 1500 },
    { name: "Feb", ELO: 1550 },
    { name: "Mar", ELO: 1600 },
    { name: "Apr", ELO: 1580 },
    { name: "May", ELO: 1620 },
  ];

  // Sample match history data
  const matchHistory = [
    {
      heroImage: "/charicon/shiv.webp",
      elo: 1600,
      result: "+20",
      duration: "35m",
      kda: "10/2/5",
      networth: "3000",
      damage: "15000",
      lastHits: "200",
      outcome: "win",
    },
    {
      heroImage: "/charicon/shiv.webp",
      elo: 1580,
      result: "-10",
      duration: "40m",
      kda: "8/4/6",
      networth: "2800",
      damage: "12000",
      lastHits: "180",
      outcome: "loss",
    },
  ];

  return (
    <>
      <div className="profile-container">
        <div className="profile-info">
          <img src={user.avatar} alt="Profile" className="profile-picture" />
          <div className="profile-details">
            <h2>{user.username}</h2>
            <p className="elo-rating">Current ELO: 1620</p>
            <a href={user.profileUrl} target="_blank" rel="noopener noreferrer">
              View Steam Profile
            </a>
          </div>
        </div>

        <div className="button">
          <button
            className="edit-profile-button"
            onClick={() => console.log("Edit Profile clicked")}
          >
            Edit Profile
          </button>
        </div>
      </div>

      <div className="profile-stats">
        <div className="profile-stats-left">
          <div className="stat-item">
            <h3>Last Match</h3>
            <p className="stat-value">Sep 2, 2024</p>
          </div>
          <div className="stat-item">
            <h3>Wins</h3>
            <p className="stat-value">44</p>
          </div>
          <div className="stat-item">
            <h3>Losses</h3>
            <p className="stat-value">27</p>
          </div>
          <div className="stat-item">
            <h3>Winrate</h3>
            <p className="stat-value">61.97%</p>
          </div>
        </div>
        <div className="profile-stats-right">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="ELO" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="match-history">
        <h3>Matches</h3>
        {matchHistory.map((match, index) => (
          <div className={`match-item ${match.outcome}`}>
            <div className="match-hero-container">
              <div className="match-hero-background" />
              <img
                src={match.heroImage}
                alt="Hero"
                className="match-hero-image"
              />
            </div>
            <div className="match-details">
              <div className="match-detail-item">
                <p className="detail-title">ELO</p>
                <p className="detail-value">{match.elo}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">Result</p>
                <p className="detail-value">{match.result}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">Duration</p>
                <p className="detail-value">{match.duration}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">KDA</p>
                <p className="detail-value">{match.kda}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">Networth</p>
                <p className="detail-value">{match.networth}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">Damage</p>
                <p className="detail-value">{match.damage}</p>
              </div>
              <div className="match-detail-item">
                <p className="detail-title">Last Hits</p>
                <p className="detail-value">{match.lastHits}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProfilePage;
