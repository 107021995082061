import React from "react";
import Tabs from "../components/Tabs";
import "./ItemsPage.css"; // Import the CSS for styling

function ItemsPage() {
  return (
    <div className="items-page">
      <h1>Items</h1>
      <Tabs />
    </div>
  );
}

export default ItemsPage;
