// src/components/SearchPlayers.jsx

import React, { useState } from "react";
import "./SearchPlayers.css"; // Optional for styling

const SearchPlayers = () => {
  const [query, setQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    // Perform the search action here (e.g., API call or filtering)
    console.log(`Searching for players: ${query}`);
  };

  return (
    <div className="search-players-container">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search for players"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="search-input"
        />
        <button type="submit" className="search-button">
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchPlayers;
