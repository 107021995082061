import React from "react";
import { Link } from "react-router-dom";
import "../pages/GuidesPage.css";

// Define available heroes for the filter
const heroes = [
  "Abrams",
  "Bebop",
  "Dynamo",
  "Grey Talon",
  "Haze",
  "Infernus",
  "Ivy",
  "Kelvin",
  "Lady Geist",
  "Lash",
  "McGinnis",
  "Mo & Krill",
  "Paradox",
  "Pocket",
  "Seven",
  "Shiv",
  "Vindicta",
  "Viscous",
  "Warden",
  "Wraith",
  "Yamato",
];

// Define available categories for the filter
const categories = ["Hero", "General"];

const Sidebar = ({ onHeroChange, onCategoryChange }) => {
  return (
    <div className="sidebar">
      <h2>Options</h2>
      <Link to="/create-guide">
        <button className="create-guide-button">Create a Guide</button>
      </Link>
      <div className="filter">
        <h3>Filter by Hero</h3>
        <select onChange={(e) => onHeroChange(e.target.value)}>
          <option value="">All Heroes</option>
          {heroes.map((hero) => (
            <option key={hero} value={hero}>
              {hero}
            </option>
          ))}
        </select>
      </div>
      <div className="filter">
        <h3>Filter by Category</h3>
        <select onChange={(e) => onCategoryChange(e.target.value)}>
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Sidebar;
