import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./GuideDetailPage.css";
import Modal from "react-modal";

import {
  IconEye,
  IconLike,
  IconComment,
  IconBxUpvote,
  IconBxDownvote,
  IconBxsUpvote,
  IconBxsDownvote,
  IconTwitch,
  IconTwitter,
  IconYouTube,
} from "../icons";
import LoadingSpinner from "./LoadingSpinner";
const GuideDetailPage = () => {
  const { guideSlug } = useParams();
  const [guide, setGuide] = useState(null);
  const [error, setError] = useState(null);
  const [isItemBuildVisible, setIsItemBuildVisible] = useState(true); // State for collapsing/expanding
  const [triangleClass, setTriangleClass] = useState("triangle");
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [user, setUser] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [userVotes, setUserVotes] = useState({});
  const [steamId, setSteamId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [isGuideContentVisible, setIsGuideContentVisible] = useState(true);
  const [guideContentTriangleClass, setGuideContentTriangleClass] =
    useState("triangle");
  const [guideContentClass, setGuideContentClass] = useState(
    "guide-content-hidden"
  );
  const [isItemContentVisible, setIsItemContentVisible] = useState(true);
  const [itemContentTriangleClass, setItemContentTriangleClass] =
    useState("triangle");
  const [itemContentClass, setItemContentClass] = useState(
    "item-content-hidden"
  );
  const [isDiscussionVisible, setIsDiscussionVisible] = useState(true);
  const [discussionTriangleClass, setDiscussionTriangleClass] =
    useState("triangle");
  const [discussionContentClass, setDiscussionContentClass] = useState(
    "discussion-content-shown"
  );
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // dark gray overlay with 50% opacity
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#333", // dark gray background
      color: "#fff", // white text color
      padding: "20px",
      border: "none",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0,0,0,0.5)", // add some shadow
      fontSize: "14px", // smaller font size
    },
  };
  useEffect(() => {
    const tableOfContentsContainer = document.querySelector(
      ".table-of-contents-container"
    );
    const innerGuide = document.querySelector(".inner-guide");
    const titleCard = document.querySelector(".title-card");

    const handleScroll = () => {
      if (innerGuide && titleCard) {
        if (window.scrollY > titleCard.offsetHeight) {
          tableOfContentsContainer.style.position = "fixed";
          tableOfContentsContainer.style.top = "0";
          tableOfContentsContainer.style.transform = "none";
        } else {
          tableOfContentsContainer.style.position = "absolute";
          tableOfContentsContainer.style.top = "50%";
          tableOfContentsContainer.style.transform = "translateY(-50%)";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/current-user",
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          // Update avatar URL to use high resolution
          data.avatar = data.avatar.replace(".jpg", "_full.jpg");
          setUser(data);
          setSteamId(data.steamId);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, []);
  const hasMountedRef = useRef(false);

  const upvoteGuide = () => {
    handleVote("upvote");
  };

  const downvoteGuide = () => {
    handleVote("downvote");
  };
  useEffect(() => {
    if (hasMountedRef.current) return;
    hasMountedRef.current = true;

    const fetchGuide = async () => {
      try {
        const response = await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setGuide(data);
      } catch (error) {
        setError("Failed to load guide");
      }
    };

    const incrementViewCount = async () => {
      try {
        await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}/views`,
          {
            method: "POST",
          }
        );
      } catch (error) {
        console.error("Error incrementing view count:", error);
      }
    };

    fetchGuide();
    incrementViewCount();
  }, [guideSlug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError("You need to log in before commenting.");
      return;
    }
    try {
      const response = await fetch(
        `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}/comments`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            comment: newComment,
            username: user.username,
            steamId: user.steamId,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setComments((prevComments) => [...prevComments, data]);
        setNewComment(""); // Clear input field
      } else {
        console.error("Failed to post comment");
      }
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}/comments`
        );
        if (response.ok) {
          const data = await response.json();
          setComments(data);
        } else {
          console.error("Failed to load comments");
        }
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    fetchComments();
  }, [guideSlug]);

  const getItemCost = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 2]; // Second last part of the path is the cost
  };

  // Extract item name from the filename
  const getItemName = (filename) => {
    return filename.split("-").pop().replace(".png", "").replace(/_/g, " ");
  };

  const handleVote = async (type) => {
    if (!steamId) {
      setModalIsOpen(true);
      return;
    }
    if (type === "upvote") {
      document.querySelector(".vote-count").classList.add("upvoted");
      setTimeout(() => {
        document.querySelector(".vote-count").classList.remove("upvoted");
      }, 500);
    } else if (type === "downvote") {
      document.querySelector(".vote-count").classList.add("downvoted");
      setTimeout(() => {
        document.querySelector(".vote-count").classList.remove("downvoted");
      }, 500);
    }
    try {
      const response = await fetch(
        `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guide._id}/vote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type, steamId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit vote.");
      }

      const updatedVoteCount = await response.json();

      // Update local vote count
      setGuide({
        ...guide,
        voteCount: updatedVoteCount.voteCount,
      });

      // Update user votes and persist them in local storage
      const updatedVotes = { ...userVotes, [guide._id]: type };
      setUserVotes(updatedVotes);
      localStorage.setItem("userVotes", JSON.stringify(updatedVotes));
    } catch (error) {
      console.error("Error submitting vote:", error);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!guide) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const toggleItemBuildVisibility = () => {
    setIsItemBuildVisible(!isItemBuildVisible);
    setTriangleClass(
      triangleClass === "triangle" ? "triangle triangle-up" : "triangle"
    );
    setItemContentClass(
      itemContentClass === "item-build-content-hidden"
        ? "item-build-content-shown"
        : "item-build-content-hidden"
    );
  };
  const toggleGuideContentVisibility = () => {
    setIsGuideContentVisible(!isGuideContentVisible);
    setGuideContentTriangleClass(
      guideContentTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
    setGuideContentClass(
      guideContentClass === "guide-content-hidden"
        ? "guide-content-shown"
        : "guide-content-hidden"
    );
  };
  const toggleDiscussionVisibility = () => {
    setIsDiscussionVisible(!isDiscussionVisible);
    setDiscussionTriangleClass(
      discussionTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
    setDiscussionContentClass(
      discussionContentClass === "discussion-content-shown"
        ? "discussion-content-hidden"
        : "discussion-content-shown"
    );
  };

  const getItemBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#fbab4d";
    } else if (path.includes("vitality")) {
      return "#87c81d";
    } else if (path.includes("spirit")) {
      return "#dd9cff";
    }
    return "transparent"; // Default if no match
  };

  const getItemCostBackgroundColor = (path) => {
    if (path.includes("weapon")) {
      return "#ca7a03";
    } else if (path.includes("vitality")) {
      return "#659819";
    } else if (path.includes("spirit")) {
      return "#8b55b4";
    }
    return "transparent"; // Default if no match
  };
  return (
    <div className="inner-guide">
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Login Required"
        >
          <h2>You must be logged in to vote</h2>
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
      <div
        className="title-card"
        style={{
          backgroundImage: `url(/splash/${guide.hero.replace(" ", "-")}.webp)`,
        }}
      >
        <h1>{guide.title}</h1>
        <h2>
          {guide.hero} Build Guide by {guide.username}
        </h2>
        <div className="vote-count">
          <span>
            {" "}
            <IconLike style={{ margin: "0 5 0 0px" }} />
            {guide.voteCount} Upvotes
          </span>
          <span>
            {" "}
            <IconEye style={{ margin: "0 5 0 0px" }} />
            {guide.views} Views
          </span>
          <span>
            {" "}
            <IconComment style={{ margin: "0 5 0 0px" }} />
            {comments.length} Comments
          </span>
        </div>
        <div className="social-links-bar">
          <span className="social-bar-follow">Follow</span>

          {guide.socialLinks && (
            <ul>
              {guide.socialLinks.twitch && (
                <li>
                  <a href={guide.socialLinks.twitch} target="_blank">
                    <IconTwitch />
                  </a>
                </li>
              )}
              {guide.socialLinks.youtube && (
                <li>
                  <a href={guide.socialLinks.youtube} target="_blank">
                    <IconYouTube />
                  </a>
                </li>
              )}
              {guide.socialLinks.twitter && (
                <li>
                  <a href={guide.socialLinks.twitter} target="_blank">
                    <IconTwitter />
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
      {user && user.steamId === guide.authorSteamId && (
        <button style={{ color: "white", marginBottom: "15px" }}>
          <a
            href={`/guides/${guideSlug}/edit`}
            style={{ color: "white", textDecoration: "none" }}
          >
            Edit Guide
          </a>
        </button>
      )}
      {/* Collapsible Item Build Section */}
      {guide.items && guide.items.length > 0 && (
        <div className="item-build-section">
          <h3 onClick={toggleItemBuildVisibility} className="collapse-header">
            Items
            <div className={triangleClass} />
          </h3>
          <div className={`item-build-content ${itemContentClass}`}>
            <div className="items-display-grid">
              {guide.items.map((itemPath) => {
                const filename = itemPath.split("/").pop();
                const itemName = getItemName(filename);
                return (
                  <div
                    key={itemPath}
                    className="item-icon-wrapper"
                    style={{
                      backgroundColor: getItemBackgroundColor(itemPath),
                    }}
                  >
                    <div
                      className="item-cost"
                      style={{
                        backgroundColor: getItemCostBackgroundColor(itemPath),
                      }}
                    >
                      <img
                        src={`/20px-Souls_icon.png`}
                        alt={itemName}
                        className="souls"
                      />
                      {getItemCost(itemPath)}
                    </div>
                    <img src={itemPath} alt={itemName} className="item-icon" />
                    <div className="item-name">{itemName}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* Table of Contents */}
      {guide.chapters && (
        <div className="table-of-contents-container">
          <div className="vote-buttons">
            <div className="label-wrapper"></div>
            <button onClick={() => upvoteGuide()} className="upvote-button">
              Upvote
              <IconBxsUpvote />
            </button>
            <button onClick={() => downvoteGuide()} className="downvote-button">
              Downvote
              <IconBxsDownvote />
            </button>
          </div>
          <div className="table-of-contents">
            <div className="toc-top">
              <h3>{guide.title}</h3>
              <p>
                <a href={`/profile/${guide.authorSteamId}`} target="_blank">
                  {guide.username}
                </a>
              </p>
            </div>
            <ul>
              {guide.chapters.map((chapter) => (
                <li key={chapter._id}>
                  <a
                    href={`#chapter-${chapter._id}`}
                    onClick={() => {
                      setIsGuideContentVisible(true);
                      setGuideContentTriangleClass("triangle triangle-up");
                      setGuideContentClass("guide-content-shown");
                    }}
                  >
                    {chapter.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Guide Content Section */}
      {guide.chapters && (
        <div className="guide-content-section">
          <h3
            onClick={toggleGuideContentVisibility}
            className="collapse-header"
          >
            Guide Content
            <div className={guideContentTriangleClass} />
          </h3>
          <div
            className={`guide-content ${
              isGuideContentVisible
                ? "guide-content-shown"
                : "guide-content-hidden"
            }`}
          >
            {guide.chapters.map((chapter) => (
              <div
                key={chapter._id}
                className="chapter"
                id={`chapter-${chapter._id}`}
              >
                <h3>{chapter.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: chapter.content }} />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Collapsible Discussion Section */}
      <div className="discussion-section">
        <h3 onClick={toggleDiscussionVisibility} className="collapse-header">
          Discussion ({comments.length})
          <div className={discussionTriangleClass} />
        </h3>
        <div className={`discussion-content ${discussionContentClass}`}>
          <div className="comment-section">
            {comments.length === 0 && <p>No comments yet!</p>}
            {!user && (
              <p>
                You need to <a href="/login">log in</a> before commenting.
              </p>
            )}
            <form onSubmit={handleSubmit}>
              <textarea
                placeholder="Leave a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <button type="submit">Submit</button>
            </form>

            <div className="comments">
              {comments
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((comment) => (
                  <div
                    className="comment-main"
                    style={{ backgroundColor: "#142034" }}
                    key={comment.id}
                  >
                    <div className="comment-header">
                      {comment.steamId ? (
                        <a
                          href={`https://steamcommunity.com/profiles/${comment.steamId}`}
                          target="_blank"
                        >
                          {comment.username}
                        </a>
                      ) : (
                        <span>{comment.username}</span>
                      )}
                      <span style={{ color: "#87add9" }}>
                        {" "}
                        —{" "}
                        {comment.createdAt
                          ? new Date(comment.createdAt).toLocaleString()
                          : "Unknown Date"}
                      </span>
                    </div>
                    <p>{comment.comment}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideDetailPage;
