const items = [
  { path: "/itemicons/weapon/500", filename: "100px-Basic_Magazine.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Close_Quarters.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Headshot_Booster.png" },
  { path: "/itemicons/weapon/500", filename: "100px-High-Velocity_Mag.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Hollow_Point_Ward.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Monster_Rounds.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Rapid_Rounds.png" },
  { path: "/itemicons/weapon/500", filename: "100px-Restorative_Shot.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Active_Reload.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Berserker.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Fleetfoot.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Kinetic_Dash.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Long_Range.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Melee_Charge.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Mystic_Shot.png" },
  { path: "/itemicons/weapon/1250", filename: "100px-Slowing_Bullets.png" },
  {
    path: "/itemicons/weapon/1250",
    filename: "100px-Soul_Shredder_Bullets.png",
  },
  { path: "/itemicons/weapon/1250", filename: "100px-Swift_Striker.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Alchemical_Fire.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Burst_Fire.png" },
  {
    path: "/itemicons/weapon/3000",
    filename: "100px-Escalating_Resilience.png",
  },
  { path: "/itemicons/weapon/3000", filename: "100px-Headhunter.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Heroic_Aura.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Hunter's_Aura.png" },
  {
    path: "/itemicons/weapon/3000",
    filename: "100px-Intensifying_Magazine.png",
  },
  { path: "/itemicons/weapon/3000", filename: "100px-Point_Blank.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Pristine_Emblem.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Sharpshooter.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Tesla_Bullets.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Titanic_Magazine.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Toxic_Bullets.png" },
  { path: "/itemicons/weapon/3000", filename: "100px-Warp_Stone.png" },
  {
    path: "/itemicons/weapon/6300",
    filename: "100px-Crippling_Headshot.png",
  },
  { path: "/itemicons/weapon/6300", filename: "100px-Frenzy.png" },
  { path: "/itemicons/weapon/6300", filename: "100px-Glass_Cannon.png" },
  { path: "/itemicons/weapon/6300", filename: "100px-Lucky_Shot.png" },
  { path: "/itemicons/weapon/6300", filename: "100px-Ricochet.png" },
  { path: "/itemicons/weapon/6300", filename: "100px-Silencer.png" },
  { path: "/itemicons/weapon/6300", filename: "100px-Siphon_Bullets.png" },
  {
    path: "/itemicons/weapon/6300",
    filename: "100px-Spiritual_Overflow.png",
  },
  { path: "/itemicons/weapon/6300", filename: "100px-Vampiric_Burst.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Enduring_Spirit.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Extra_Health.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Extra_Regen.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Extra_Stamina.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Healing_Rite.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Melee_Lifesteal.png" },
  { path: "/itemicons/vitality/500", filename: "100px-Sprint_Boots.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Bullet_Armor.png" },
  {
    path: "/itemicons/vitality/1250",
    filename: "100px-Bullet_Lifesteal_(item).png",
  },
  { path: "/itemicons/vitality/1250", filename: "100px-Combat_Barrier.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Debuff_Reducer.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Divine_Barrier.png" },
  {
    path: "/itemicons/vitality/1250",
    filename: "100px-Enchanter's_Barrier.png",
  },
  { path: "/itemicons/vitality/1250", filename: "100px-Enduring_Speed.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Healbane.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Healing_Booster.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Health_Nova.png" },
  {
    path: "/itemicons/vitality/1250",
    filename: "100px-Reactive_Barrier.png",
  },
  {
    path: "/itemicons/vitality/1250",
    filename: "100px-Restorative_Locket.png",
  },
  { path: "/itemicons/vitality/1250", filename: "100px-Return_Fire.png" },
  { path: "/itemicons/vitality/1250", filename: "100px-Spirit_Armor.png" },
  {
    path: "/itemicons/vitality/1250",
    filename: "100px-Spirit_Lifesteal_(item).png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Debuff_Remover.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Fortitude.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Improved_Bullet_Armor.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Improved_Spirit_Armor.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Lifestrike.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Majestic_Leap.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Metal_Skin.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Rescue_Beam.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Superior_Stamina.png",
  },
  {
    path: "/itemicons/vitality/3000",
    filename: "100px-Veil_Walker.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Colossus.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Inhibitor.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Leech.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Phantom_Strike.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Shadow_Weave.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Soul_Rebirth.png",
  },
  {
    path: "/itemicons/vitality/6300",
    filename: "100px-Unstoppable.png",
  },
  { path: "/itemicons/spirit/500", filename: "100px-Ammo_Scavenger.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Extra_Charge.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Extra_Spirit.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Infuser.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Mystic_Burst.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Mystic_Reach.png" },
  { path: "/itemicons/spirit/500", filename: "100px-Spirit_Strike.png" },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Bullet_Resist_Shredder.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Cold_Front.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Decay.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Duration_Extender.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Improved_Cooldown.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Mystic_Vulnerability.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Quicksilver_Reload.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Slowing_Hex.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Suppressor.png",
  },
  {
    path: "/itemicons/spirit/1250",
    filename: "100px-Withering_Whip.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Ethereal_Shift.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Improved_Burst.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Improved_Reach.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Improved_Spirit.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Knockdown.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Mystic_Slow.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Rapid_Recharge.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Silence_Glyph.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Superior_Cooldown.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Superior_Duration.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Surge_of_Power.png",
  },
  {
    path: "/itemicons/spirit/3000",
    filename: "100px-Torment_Pulse.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Boundless_Spirit.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Curse.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Diviner's_Kevlar.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Echo_Shard.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Escalating_Exposure.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Magic_Carpet.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Mystic_Reverb.png",
  },
  {
    path: "/itemicons/spirit/6300",
    filename: "100px-Refresher.png",
  },
  // Add more items here
].map((item) => ({
  ...item,
  cost: parseInt(item.path.split("/").pop()),
  name: item.filename.replace("100px-", "").replace(".png", ""),
}));

// const itemStats = {
//   "Sprint Boots": { winrate: 51.4, usage: 43.7 },
//   "Mystic Burst": { winrate: 48.8, usage: 39.9 },
//   "Basic Magazine": { winrate: 49.7, usage: 37.2 },
//   "Extra Stamina": { winrate: 50.9, usage: 35.4 },
//   "Improved Cooldown": { winrate: 51.2, usage: 34.2 },
//   "Extra Charge": { winrate: 49.9, usage: 34.2 },
//   "Extra Spirit": { winrate: 49.4, usage: 34 },
//   "Enduring Spirit": { winrate: 50.7, usage: 33.8 },
//   "Bullet Armor": { winrate: 49.5, usage: 33.3 },
//   "Enduring Speed": { winrate: 53.2, usage: 33.1 },
//   "Spirit Armor": { winrate: 50.2, usage: 31.4 },
//   "Close Quarters": { winrate: 50.7, usage: 30 },
//   "Mystic Reach": { winrate: 52.5, usage: 29.4 },
//   "Bullet Lifesteal": { winrate: 50.6, usage: 28.8 },
//   "Spirit Lifesteal": { winrate: 52.3, usage: 27.4 },
//   "Extra Regen": { winrate: 47.8, usage: 25.9 },
//   "Soul Shredder Bullets": { winrate: 49.3, usage: 25.2 },
//   "High-Velocity Mag": { winrate: 49.1, usage: 24.8 },
//   "Mystic Vulnerability": { winrate: 53.1, usage: 23.3 },
//   "Bullet Resist Shredder": { winrate: 51.6, usage: 21.8 },
//   "Improved Spirit": { winrate: 52, usage: 21.4 },
//   "Headshot Booster": { winrate: 48.6, usage: 21.3 },
//   "Superior Cooldown": { winrate: 52, usage: 21.1 },
//   "Mystic Shot": { winrate: 47.8, usage: 19.7 },
//   "Quicksilver Reload": { winrate: 50.6, usage: 18.9 },
//   "Hollow Point Ward": { winrate: 49.5, usage: 18.5 },
//   "Rapid Rounds": { winrate: 48.3, usage: 18.3 },
//   "Duration Extender": { winrate: 50.1, usage: 17.9 },
//   Suppressor: { winrate: 53.5, usage: 17 },
//   "Active Reload": { winrate: 50.5, usage: 17 },
//   "Tesla Bullets": { winrate: 50.9, usage: 16.7 },
//   "Improved Bullet Armor": { winrate: 51.5, usage: 16.5 },
//   "Extra Health": { winrate: 49.7, usage: 16.4 },
//   "Enchanter's Barrier": { winrate: 47.4, usage: 16.1 },
//   "Monster Rounds": { winrate: 49.3, usage: 15.9 },
//   "Slowing Bullets": { winrate: 48.6, usage: 15.7 },
//   "Improved Spirit Armor": { winrate: 50.2, usage: 14.4 },
//   "Healing Rite": { winrate: 49.8, usage: 14.4 },
//   "Titanic Magazine": { winrate: 50.8, usage: 13.6 },
//   "Rapid Recharge": { winrate: 49.5, usage: 13.6 },
//   "Long Range": { winrate: 45.7, usage: 13.5 },
//   "Point Blank": { winrate: 51.6, usage: 12.7 },
//   "Improved Reach": { winrate: 54.3, usage: 12.4 },
//   "Superior Stamina": { winrate: 50.4, usage: 12 },
//   "Combat Barrier": { winrate: 46, usage: 11.7 },
//   "Healing Booster": { winrate: 51, usage: 11.5 },
//   Berserker: { winrate: 50.7, usage: 11.5 },
//   Leech: { winrate: 56.2, usage: 11.3 },
//   "Restorative Shot": { winrate: 48.2, usage: 11.3 }, // winrate not provided
//   Healbane: { winrate: 50.5, usage: 10.7 },
//   "Burst Fire": { winrate: 50, usage: 10.2 },
//   "Ammo Scavenger": { winrate: 50.8, usage: 10.1 },
//   "Pristine Emblem": { winrate: 51.1, usage: 10.1 },
//   "Toxic Bullets": { winrate: 49.3, usage: 10.1 },
//   "Swift Striker": { winrate: 49.8, usage: 9.9 },
//   "Intensifying Magazine": { winrate: 52.1, usage: 9.7 },
//   Infuser: { winrate: 51.7, usage: 9.2 },
//   "Spirit Strike": { winrate: 49.6, usage: 8.9 },
//   Sharpshooter: { winrate: 45.8, usage: 8.4 },
//   "Kinetic Dash": { winrate: 50.9, usage: 8.4 },
//   "Melee Lifesteal": { winrate: 50.7, usage: 8.1 },
//   Fleetfoot: { winrate: 53.1, usage: 8.1 },
//   Fortitude: { winrate: 48.3, usage: 7.9 },
//   "Lucky Shot": { winrate: 54.9, usage: 7.4 },
//   "Glass Cannon": { winrate: 54.6, usage: 7.2 },
//   "Superior Duration": { winrate: 55.6, usage: 7 },
//   "Mystic Slow": { winrate: 50.8, usage: 7 },
//   "Warp Stone": { winrate: 52, usage: 7 },
//   "Siphon Bullets": { winrate: 57.1, usage: 7 },
//   "Torment Pulse": { winrate: 54.9, usage: 6.6 },
//   "Boundless Spirit": { winrate: 55.1, usage: 6.1 },
//   "Reactive Barrier": { winrate: 49.4, usage: 6 },
//   "Debuff Reducer": { winrate: 50.8, usage: 5.8 },
//   "Surge of Power": { winrate: 51.0, usage: 5.8 }, // winrate not provided
//   "Cold Front": { winrate: 49.5, usage: 5.3 },
//   "Melee Charge": { winrate: 52.7, usage: 5 },
//   Ricochet: { winrate: 57.4, usage: 4.9 },
//   "Escalating Exposure": { winrate: 56.7, usage: 4.7 },
//   "Hunter's Aura": { winrate: 53.4, usage: 4.5 },
//   "Slowing Hex": { winrate: 50.7, usage: 4.5 },
//   "Health Nova": { winrate: 52.3, usage: 4.4 },
//   "Crippling Headshot": { winrate: 54.1, usage: 4.3 },
//   "Veil Walker": { winrate: 51.2, usage: 3.7 },
//   "Withering Whip": { winrate: 51.2, usage: 3.6 },
//   Lifestrike: { winrate: 54.2, usage: 3.6 },
//   "Diviner's Kevlar": { winrate: 56.8, usage: 3.3 },
//   "Spiritual Overflow": { winrate: 51.9, usage: 2.9 },
//   "Mystic Reverb": { winrate: 58.1, usage: 2.9 },
//   "Majestic Leap": { winrate: 52.6, usage: 2.9 },
//   "Heroic Aura": { winrate: 54.7, usage: 2.8 },
//   Frenzy: { winrate: 55.4, usage: 2.4 },
//   Inhibitor: { winrate: 56.3, usage: 2.2 },
//   "Ethereal Shift": { winrate: 48.5, usage: 2.1 },
//   Decay: { winrate: 47.1, usage: 1.5 },
//   Knockdown: { winrate: 48, usage: 1.5 },
//   "Metal Skin": { winrate: 45.8, usage: 1.4 },
//   Unstoppable: { winrate: 53, usage: 1.4 },
//   "Escalating Resilience": { winrate: 47.6, usage: 1.3 },
//   "Debuff Remover": { winrate: 47.2, usage: 1.1 }, // winrate not provided
//   "Echo Shard": { winrate: 53.7, usage: 1.1 },
//   "Vampiric Burst": { winrate: 55.9, usage: 1.1 },
//   Refresher: { winrate: 56.4, usage: 1.1 },
//   "Return Fire": { winrate: 44.4, usage: 1 },
//   "Rescue Beam": { winrate: 52.6, usage: 1 },
//   "Soul Rebirth": { winrate: 54.4, usage: 0.9 },
//   Silencer: { winrate: 50.2, usage: 0.9 },
//   "Silence Glyph": { winrate: 44.5, usage: 0.9 },
//   "Divine Barrier": { winrate: 47, usage: 0.8 },
//   "Phantom Strike": { winrate: 57.2, usage: 0.8 },
//   Curse: { winrate: 49.7, usage: 0.8 },
//   "Restorative Locket": { winrate: 52.9, usage: 0.6 },
//   Colossus: { winrate: 55.5, usage: 0.6 },
//   "Alchemical Fire": { winrate: 46.6, usage: 0.6 },
//   "Shadow Weave": { winrate: 52.8, usage: 0.1 },
//   "Magic Carpet": { winrate: 51.2, usage: 0.1 }, // winrate not provided
// };

export default items;
