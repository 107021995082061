import React from "react";
import "../pages/HeroDetailPage.css";

const BaseStats = ({ stats }) => {
  return (
    <div className="base-stats">
      <h2 className="base-title">Base Stats</h2>
      <ul className="base-stats-list">
        <li>
          <span>Max Health: </span>
          <span className="font-semibold">{stats.maxHealth}</span>
        </li>
        <li>
          <span>Move Speed: </span>
          <span className="font-semibold">{stats.moveSpeed}</span>
        </li>
        <li>
          <span>Light Melee Damage: </span>
          <span className="font-semibold">{stats.lightMeleeDamage}</span>
        </li>
        <li>
          <span>Heavy Melee Damage: </span>
          <span className="font-semibold">{stats.heavyMeleeDamage}</span>
        </li>
        {/* Add more base stats as needed */}
      </ul>
    </div>
  );
};

export default BaseStats;
