import React from "react";

const IvyGuide = () => {
  return (
    <div>
      <h1>Ultimate Ivy Guide ft. Catgirl</h1>
      <p>
        Hi! I'm Catgirl! I'm updating my guide because the previous one is a bit
        outdated. I've learned a lot about the game in the past two months. In
        this guide I'll be going over some general things about Ivy, followed by
        my recommended DPS build, followed by my recommended support build and a
        lot of comments and reasoning and how to use everything.
      </p>
      <p>
        I'm writing the guide on 9/7/2024 so by the time you see this, it might
        be time for a version 3 already.
      </p>

      <h2>Why do you like Ivy?</h2>
      <p>
        As of writing this guide, I have around 650 games of Ivy (1260 total
        games played, not counting private matches). I really like the
        versatility of Ivy - if you have a team with no frontline, she can
        frontline very well (ranging from frontline disruptor with
        knockdown/curse to a support frontline with heroic aura and rescue
        beam). If you want her to be a DPS carry, she's extremely good at that -
        while she isn't the highest DPS like Haze, she has insane amounts of
        survivability, mobility, and speed that they don't have.
      </p>
      <p>
        She's a blast to play as long as you want to do anything besides throw
        spells / poke out the enemy.
      </p>

      <h2>Laning with Ivy</h2>
      <p>
        Ivy is atrocious early on. Her laning is quite literally one of the
        worst in the game in terms of kill potential. She does very very low
        damage early on. Basically, the only way you can really get early kills
        are if:
      </p>
      <ul>
        <li>
          a. The enemy royally messes up and overextends and you get a charged
          melee / two off // they let you magdump them for free
        </li>
        <li>b. Your teammate gives it to you on a silver platter</li>
        <li>c. You get a parry off</li>
        <li>
          d. You gank someone and you show up behind them and you magdump them
        </li>
      </ul>
      <p>
        So when you're soloing with Ivy, I recommend not really trading with the
        enemy early on. You can dump an entire mag on an enemy and they will
        trade it back with two headshots. You want to push your lane as hard as
        possible. If they're out in the open, of course shoot them. Just know
        that extended trades don't really favor you (especially since you can't
        really shoulder peek and they can most of the time). If they give you a
        chance, running in and charged meleeing creeps really helps because,
        like I mentioned, your gun really isn't great early on.
      </p>
      <p>
        So, if Ivy's laning is atrocious, then what do we do? How do I win the
        game? I don't want to play a game with a bad hero! Oh no!
      </p>
      <p>
        The answer is simple. You push the lane, and then you leave the lane.
        There's no real point in overcommitting onto the enemy if you can't even
        outdamage their healer minion. What do we do when we leave the lane?
      </p>
      <ul>
        <li>
          a. Break boxes - Find boxes that are near every lane and just run over
          and break them
        </li>
        <li>
          b. Gank a lane - So her gun kind of sucks but if you can do 100-200
          damage for free, that's huge. This is especially good if you have a
          Health Nova - you can literally bring three people from no HP to max
          HP.
        </li>
        <li>
          c. Kill a few jungle camps. If you're going to do this, make sure your
          creep wave is pushed. Your creep wave is worth 300g/25s. Your jungle
          camps are 150g @ 2 minutes and when the big minions spawn, it's still
          like 300g / 6 minutes. Your wave first, then the jungle.
        </li>
        <li>
          d. Soak a solo lane - This is going to take a little bit of
          explaining.
        </li>
      </ul>
      <p>
        Pre-10 minutes, all souls in solo lanes are duplicated if another person
        enters the lane. So we can make a strategy to exploit this:
      </p>
      <ol>
        <li>
          Push your wave out. Get your 300g. Tell your teammate you are leaving.
        </li>
        <li>
          Enter your solo's lane. If they are at their tower, you can get +300g.
          Help by shooting the enemy or help last hitting/denying.
        </li>
        <li>Push one lane with your solo lane - 300g</li>
        <li>
          Go back to your lane - your creeps should be at your tower - 300g
        </li>
      </ol>
      <p>
        So in 2 waves, instead of 600g, you can get 1200g. That's super good
        souls per minute! Of course, it's not always this cut and dry and nice -
        especially if enemies know how to actually deny. But you'll still come
        ahead on average and you can do this multiple waves.
      </p>
      <p>
        Do NOT do this to a duo lane - you will int their soul gains. Souls are
        only duplicated when going from solo to duo. They are split when you go
        from duo to trio.
      </p>
      <p>
        So I mentioned that your early game is atrocious and you can't even
        really outdamage regen - but this changes at around the 2-2.5k souls
        mark (for example, basic mag + close quarters + active reload you start
        to do damage). Once you hit there, then it starts to be more worth it.
        And of course, this is completely discounting the enemy being super out
        of position. If you see an advantage, then take it.
      </p>

      <h2>Early-Midgame with Ivy</h2>
      <p>
        When I say Early-Midgame, I'm referring basically to minute 8-14 of a
        match. Something like that.
      </p>
      <p>
        I'll go into this in a bit more depth when I introduce the item builds,
        but Ivy kind of sucks early-mid until you get a few items - you're
        basically a glorified melee range stun/ult bot / walking health nova.
        You'll start to think you're hot shit and then a pocket runs in and does
        1600 AoE damage instantaneously and you're like "oh...."
      </p>
      <p>So what can we do?</p>
      <ul>
        <li>
          a. Push their guardian/walker - If you lost your guardian, that's
          alright. But we really want to stay in lane. You have your ult by now,
          so here's a little bit of Ivy tech: Press 4, then press 3, and by the
          time you come out of your stone form you'll be flying away.
        </li>
        <li>
          b. If you try doing the above and they contest you with 2 people, then
          go start killing your jungle camps. By now you should have 333 so you
          don't actually have to go back to base - you have a 30% heal every 20
          seconds. When they leave, go back and push. Make sure to *launder your
          souls if you have too many unsecured souls. AND REMEMBER - YOUR WAVE
          IS WORTH 300G-400G EVERY 25S. A MEDIUM JUNGLE CAMP IS WORTH ~300G
          ONCE. EVEN IF YOU'RE JUNGLING GO CATCH YOUR WAVE.
        </li>
      </ul>
      <p>
        Laundering souls - Currently, if you buy an item and then sell it, the
        unsecured souls used to buy the item become secured. This may be patched
        out in the future, but currently, it's been in the game for months.
      </p>
      <li>
        c. You can also go run the urn. Currently, when you grab the urn, you
        get a +3 sprint speed buff // +5 if you're behind. So you can run it,
        but if you want you can also fly it (18m/s - it's a bit faster but you
        don't get to farm boxes while you run the urn). Press your ult when you
        get to the "U" in urn if you're flying it and you can solo it.
      </li>
      <p>
        Once you get something like Heroic Aura + Enduring Speed + Level 2
        tether, you start to become really strong and you start to be really
        impactful in teamfight.
      </p>

      <h2>Skill Build</h2>
      <p>Ivy has 4 powerspikes in her build:</p>
      <ul>
        <li>
          Stoneform t3 - Turns your stoneform from a 10% heal every 20s to a 30%
          heal every 20s. This is absolutely insane and makes you have the most
          sustain in the game. It's absurd. I highly recommend you max this one
          first in every build no matter what.
        </li>
        <li>
          Tether t2 - +2m/s for you and tethered ally - This one is actually
          really good, however, the problem with tether is that it increases
          your fire rate. Having a really fast fire rate when you can't
          perma-slide seems very silly.
        </li>
        <li>
          Airdrop t3 - +4.5 second silence is a huge game changer in teamfights.
        </li>
        <li>
          Kudzu t3 - +60 DPS - brings it up to 120DPS which is a lot. If they
          sit in it. But they don't always sit in it.
        </li>
      </ul>

      <h2>Item Builds</h2>
      <p>
        Items are a little bit tricky to recommend, because it's not necessarily
        something that's going to be easy to fit into a guide because item
        builds are really situational. Here's what I recommend:
      </p>
      <p>
        1. <strong>DPS Build</strong>:
      </p>
      <ul>
        <li>
          Jungle + Suppressor + Potent (Do not get suppressor t3, it won't help
          you much and it will just mean you die with half the health)
        </li>
        <li>Close Quarters + Heroic Aura + Enduring Speed + [Extra Item]</li>
      </ul>
      <p>Extra Item options:</p>
      <ul>
        <li>
          Lightweight - Movement speed + basic attack damage + mag+shotgun
          damage
        </li>
        <li>Supreme - Just a really good stat item</li>
        <li>Sturdy - Up to 5% cdr + lots of armor</li>
        <li>Shadow - Up to +60% reduced enemy weapon range</li>
      </ul>
      <p>
        2. <strong>Support Build</strong>:
      </p>
      <ul>
        <li>Heroic Aura + Rescue Beam + Health Nova + [Extra Item]</li>
      </ul>
      <p>Extra Item options:</p>
      <ul>
        <li>
          Lightweight - Movement speed + basic attack damage + mag+shotgun
          damage
        </li>
        <li>Arcane - +2 Energy Regen / sec + 40% energy regen speed</li>
        <li>Sturdy - Up to 5% cdr + lots of armor</li>
        <li>Shadow - Up to +60% reduced enemy weapon range</li>
      </ul>

      <h2>Conclusion</h2>
      <p>
        Don't be afraid to adjust your build based on the match and the enemy
        team composition. Ivy is a versatile hero and can fill multiple roles
        effectively with the right setup.
      </p>
    </div>
  );
};

export default IvyGuide;
