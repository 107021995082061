// HeroDetailPage.js
import React from "react";
import { useParams } from "react-router-dom";
import "./HeroDetailPage.css";
import WeaponStats from "../components/WeaponStats";
import BaseStats from "../components/BaseStats";
import Abilities from "../components/Abilities";
import HeroSpecificGuides from "../components/HeroSpecificGuides";
import { useState, useEffect } from "react";
import heroData from "../components/heroData.js";

const HeroDetailPage = () => {
  const { heroName } = useParams();
  const decodedHeroName = heroName.replace("%20", "_").replace("&", "_"); // Replace & with _
  const formattedHeroName = heroName.replace(/_/g, " ").replace(/and/g, "&");

  const [guides, setGuides] = useState([]);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/`
        );
        const data = await response.json();
        const heroGuides = data.filter(
          (guide) => guide.hero === formattedHeroName
        );
        setGuides(heroGuides);
      } catch (error) {
        console.error(error);
      }
    };
    fetchGuides();
  }, [heroName]);

  const heroIconUrl = `/heroes/${decodedHeroName
    .toLowerCase()
    .replace(" ", "_")}/assets/hero_thumbnail.png`;
  const currentHeroData = heroData[decodedHeroName.replace(" ", "_")]; // Get data for current hero
  if (!currentHeroData) {
    return <div>Hero not found</div>;
  }

  console.log(guides);
  return (
    <>
      <div className="hero-detail-container">
        <div className="hero-info">
          <img
            src={heroIconUrl}
            alt={`${heroName} icon`}
            className="hero-icon-detail"
          />
          <h1 className="hero-name">
            {formattedHeroName.charAt(0).toUpperCase() +
              formattedHeroName.slice(1)}
          </h1>
        </div>
        <div className="hero-bottom-grid">
          <div className="left-column">
            <WeaponStats stats={currentHeroData.weaponStats} />
            <BaseStats stats={currentHeroData.baseStats} />
          </div>
          <div className="right-column">
            <Abilities
              abilities={currentHeroData.abilities}
              heroName={heroName}
            />
          </div>
        </div>
      </div>
      <div className="guides-section">
        <h2>Guides for {formattedHeroName}</h2>
        <HeroSpecificGuides guides={guides} />
      </div>
    </>
  );
};

export default HeroDetailPage;
