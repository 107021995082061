import React from "react";
import { Link } from "react-router-dom";

const HeroSpecificGuides = ({ guides }) => {
  const filteredGuides = guides.filter((guide) => guide.hero); // Filter out guides without a hero

  const formatTitleToURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, "_").replace("&", "and");
  };

  const handleVote = (id, type) => {
    console.log(`Voting ${type} on guide ${id}`);
    // Implement voting logic here
  };

  return (
    <div className="featured-guides">
      <ul>
        {filteredGuides.map((guide) => (
          <li key={guide._id} className="guide-item">
            <div className="vote-container">
              <button
                className="vote-button upvote"
                onClick={() => handleVote(guide._id, "upvote")}
              >
                ▲
              </button>
              <span className="vote-count">{guide.voteCount || 0}</span>
              <button
                className="vote-button downvote"
                onClick={() => handleVote(guide._id, "downvote")}
              >
                ▼
              </button>
            </div>
            <div className="guide-info">
              <img
                src={`/charicon/${formatTitleToURL(guide.hero)}.webp`}
                alt={guide.hero}
                className="hero-icon"
              />
              <div className="guide-links">
                <Link
                  to={`/guides/${formatTitleToURL(guide.title)}`}
                  className="guide-link"
                >
                  <h3>{guide.title}</h3>
                </Link>
                {guide.username && (
                  <div className="guide-username">
                    <Link to={`/profile/${guide.username}`}>
                      {guide.username}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeroSpecificGuides;
