const HeroesData = {
  "Patch 1.2": [
    {
      id: 1,
      name: "Seven",
      icon: "/charicon/seven.webp",
      winrate: 58.89,
      pickrate: 93.26,
      totalGames: 864937,
      totalWins: 509379,
    },
    {
      id: 2,
      name: "Warden",
      icon: "/charicon/warden.webp",
      winrate: 53.26,
      pickrate: 49.51,
      totalGames: 459188,
      totalWins: 244595,
    },
    {
      id: 3,
      name: "Haze",
      icon: "/charicon/haze.webp",
      winrate: 53.17,
      pickrate: 92.07,
      totalGames: 853978,
      totalWins: 454076,
    },
    {
      id: 4,
      name: "McGinnis",
      icon: "/charicon/mcginnis.webp",
      winrate: 51.94,
      pickrate: 60.6,
      totalGames: 562101,
      totalWins: 291979,
    },
    {
      id: 5,
      name: "Kelvin",
      icon: "/charicon/kelvin.webp",
      winrate: 51.29,
      pickrate: 44.36,
      totalGames: 411415,
      totalWins: 211033,
    },
    {
      id: 6,
      name: "Lady Geist",
      icon: "/charicon/ladygeist.webp",
      winrate: 51.27,
      pickrate: 48.31,
      totalGames: 448051,
      totalWins: 229743,
    },
    {
      id: 7,
      name: "Mo & Krill",
      icon: "/charicon/mokrill.webp",
      winrate: 51.23,
      pickrate: 34.55,
      totalGames: 320481,
      totalWins: 164206,
    },
    {
      id: 8,
      name: "Abrams",
      icon: "/charicon/abrams.webp",
      winrate: 51.14,
      pickrate: 55.17,
      totalGames: 511749,
      totalWins: 261725,
    },
    {
      id: 9,
      name: "Lash",
      icon: "/charicon/lash.webp",
      winrate: 49.96,
      pickrate: 37.21,
      totalGames: 345130,
      totalWins: 172448,
    },
    {
      id: 10,
      name: "Pocket",
      icon: "/charicon/pocket.webp",
      winrate: 49.57,
      pickrate: 46.81,
      totalGames: 434193,
      totalWins: 215240,
    },
    {
      id: 11,
      name: "Wraith",
      icon: "/charicon/wraith.webp",
      winrate: 49.52,
      pickrate: 82.09,
      totalGames: 761358,
      totalWins: 377030,
    },
    {
      id: 12,
      name: "Bebop",
      icon: "/charicon/bebop.webp",
      winrate: 49.1,
      pickrate: 70.77,
      totalGames: 656443,
      totalWins: 322342,
    },
    {
      id: 13,
      name: "Ivy",
      icon: "/charicon/ivy.webp",
      winrate: 49.08,
      pickrate: 35.35,
      totalGames: 327905,
      totalWins: 160968,
    },
    {
      id: 14,
      name: "Yamato",
      icon: "/charicon/yamato.webp",
      winrate: 48.86,
      pickrate: 47.0,
      totalGames: 435902,
      totalWins: 212983,
    },
    {
      id: 15,
      name: "Viscous",
      icon: "/charicon/viscous.webp",
      winrate: 48.64,
      pickrate: 35.65,
      totalGames: 330722,
      totalWins: 160873,
    },
    {
      id: 16,
      name: "Infernus",
      icon: "/charicon/infernus.webp",
      winrate: 48.42,
      pickrate: 81.26,
      totalGames: 753699,
      totalWins: 364982,
    },
    {
      id: 17,
      name: "Shiv",
      icon: "/charicon/shiv.webp",
      winrate: 47.88,
      pickrate: 44.15,
      totalGames: 409539,
      totalWins: 196116,
    },
    {
      id: 18,
      name: "Dynamo",
      icon: "/charicon/dynamo.webp",
      winrate: 47.04,
      pickrate: 45.3,
      totalGames: 420146,
      totalWins: 197658,
    },
    {
      id: 19,
      name: "Grey Talon",
      icon: "/charicon/greytalon.webp",
      winrate: 46.27,
      pickrate: 67.08,
      totalGames: 622141,
      totalWins: 287885,
    },
    {
      id: 20,
      name: "Paradox",
      icon: "/charicon/paradox.webp",
      winrate: 44.57,
      pickrate: 47.21,
      totalGames: 437905,
      totalWins: 195215,
    },
    {
      id: 21,
      name: "Vindicta",
      icon: "/charicon/vindicta.webp",
      winrate: 43.83,
      pickrate: 82.19,
      totalGames: 762345,
      totalWins: 334187,
    },
  ],
  "Patch 1.1": [
    {
      id: 1,
      name: "Seven but last patch",
      icon: "path_to_icon",
      winrate: 58.89,
      pickrate: 93.26,
      totalGames: 864937,
      totalWins: 509379,
    },
    {
      id: 2,
      name: "Warden",
      icon: "path_to_icon",
      winrate: 53.26,
      pickrate: 49.51,
      totalGames: 459188,
      totalWins: 244595,
    },
    {
      id: 3,
      name: "Haze",
      icon: "path_to_icon",
      winrate: 53.17,
      pickrate: 92.07,
      totalGames: 853978,
      totalWins: 454076,
    },
    {
      id: 4,
      name: "McGinnis",
      icon: "path_to_icon",
      winrate: 51.94,
      pickrate: 60.6,
      totalGames: 562101,
      totalWins: 291979,
    },
    {
      id: 5,
      name: "Kelvin",
      icon: "path_to_icon",
      winrate: 51.29,
      pickrate: 44.36,
      totalGames: 411415,
      totalWins: 211033,
    },
    {
      id: 6,
      name: "Lady Geist",
      icon: "path_to_icon",
      winrate: 51.27,
      pickrate: 48.31,
      totalGames: 448051,
      totalWins: 229743,
    },
    {
      id: 7,
      name: "Mo & Krill",
      icon: "path_to_icon",
      winrate: 51.23,
      pickrate: 34.55,
      totalGames: 320481,
      totalWins: 164206,
    },
    {
      id: 8,
      name: "Abrams",
      icon: "path_to_icon",
      winrate: 51.14,
      pickrate: 55.17,
      totalGames: 511749,
      totalWins: 261725,
    },
    {
      id: 9,
      name: "Lash",
      icon: "path_to_icon",
      winrate: 49.96,
      pickrate: 37.21,
      totalGames: 345130,
      totalWins: 172448,
    },
    {
      id: 10,
      name: "Pocket",
      icon: "path_to_icon",
      winrate: 49.57,
      pickrate: 46.81,
      totalGames: 434193,
      totalWins: 215240,
    },
    {
      id: 11,
      name: "Wraith",
      icon: "path_to_icon",
      winrate: 49.52,
      pickrate: 82.09,
      totalGames: 761358,
      totalWins: 377030,
    },
    {
      id: 12,
      name: "Bebop",
      icon: "path_to_icon",
      winrate: 49.1,
      pickrate: 70.77,
      totalGames: 656443,
      totalWins: 322342,
    },
    {
      id: 13,
      name: "Ivy",
      icon: "path_to_icon",
      winrate: 49.08,
      pickrate: 35.35,
      totalGames: 327905,
      totalWins: 160968,
    },
    {
      id: 14,
      name: "Yamato",
      icon: "path_to_icon",
      winrate: 48.86,
      pickrate: 47.0,
      totalGames: 435902,
      totalWins: 212983,
    },
    {
      id: 15,
      name: "Viscous",
      icon: "path_to_icon",
      winrate: 48.64,
      pickrate: 35.65,
      totalGames: 330722,
      totalWins: 160873,
    },
    {
      id: 16,
      name: "Infernus",
      icon: "path_to_icon",
      winrate: 48.42,
      pickrate: 81.26,
      totalGames: 753699,
      totalWins: 364982,
    },
    {
      id: 17,
      name: "Shiv",
      icon: "path_to_icon",
      winrate: 47.88,
      pickrate: 44.15,
      totalGames: 409539,
      totalWins: 196116,
    },
    {
      id: 18,
      name: "Dynamo",
      icon: "path_to_icon",
      winrate: 47.04,
      pickrate: 45.3,
      totalGames: 420146,
      totalWins: 197658,
    },
    {
      id: 19,
      name: "Grey Talon",
      icon: "path_to_icon",
      winrate: 46.27,
      pickrate: 67.08,
      totalGames: 622141,
      totalWins: 287885,
    },
    {
      id: 20,
      name: "Paradox",
      icon: "path_to_icon",
      winrate: 44.57,
      pickrate: 47.21,
      totalGames: 437905,
      totalWins: 195215,
    },
    {
      id: 21,
      name: "Vindicta",
      icon: "path_to_icon",
      winrate: 43.83,
      pickrate: 82.19,
      totalGames: 762345,
      totalWins: 334187,
    },
  ],
};

export default HeroesData;
