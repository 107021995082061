// UserContext.js
import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUserData = () => {
      const userData = Cookies.get("user");
      console.log("Cookie:", userData); // Verify cookie
      if (userData) {
        try {
          const parsedData = JSON.parse(userData);
          console.log("Parsed Data:", parsedData); // Verify parsing
          setUser(parsedData);
          console.log(userData);
        } catch (error) {
          console.error("Parsing Error:", error);
        }
      }
    };
    getUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
