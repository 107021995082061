import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatTitleToURL } from "../utils";
import "./GuidesPage.css";
import Sidebar from "../components/Sidebar";
import LoadingSpinner from "../components/LoadingSpinner";
import Modal from "react-modal";
import { IconBxUpvote, IconBxDownvote } from "../icons";

const FeaturedGuides = ({ selectedHero, selectedCategory }) => {
  const [guides, setGuides] = useState([]);
  const [userVotes, setUserVotes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [steamId, setSteamId] = useState("");
  const formatHeroNameIcon = (name) =>
    name.toLowerCase().replace(/\s+/g, "_").replace("&", "and");

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // dark gray overlay with 50% opacity
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#333", // dark gray background
      color: "#fff", // white text color
      padding: "20px",
      border: "none",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0,0,0,0.5)", // add some shadow
      fontSize: "14px", // smaller font size
    },
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/guides"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        // Sort guides by voteCount in descending order
        data.sort((a, b) => b.voteCount - a.voteCount);
        setGuides(data);

        // Initialize user votes from local storage or an empty object
        const storedVotes = JSON.parse(localStorage.getItem("userVotes")) || {};
        setUserVotes(storedVotes);
        setLoading(false);
      } catch (error) {
        setError("Failed to load guides");
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/current-user",
          {
            credentials: "include",
            method: "GET", // Explicitly set method
            headers: {
              "Content-Type": "application/json", // Set content type
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          if (userData) {
            // Update avatar URL to use high resolution
            userData.avatar = userData.avatar.replace(".jpg", "_full.jpg");
            setUser(userData);
            setSteamId(userData.steamId);
            console.log(userData);
          } else {
            console.log("No user data received");
          }
        } else {
          console.error("Failed to fetch user:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchCurrentUser();
  }, []);
  console.log(user);
  const handleVote = async (guideId, type) => {
    if (!steamId) {
      setModalIsOpen(true);
      return;
    }
    if (type !== "neutral") {
      // Add or remove the 'upvoted' or 'downvoted' class to the vote-count element
      const voteCountElement = document.querySelector(`#vote-count-${guideId}`);
      if (type === "upvote") {
        voteCountElement.classList.add("upvoted");
        voteCountElement.classList.remove("downvoted");
      } else if (type === "downvote") {
        voteCountElement.classList.add("downvoted");
        voteCountElement.classList.remove("upvoted");
      }

      // Remove the animation classes after 0.5 seconds
      setTimeout(() => {
        voteCountElement.classList.remove("upvoted", "downvoted");
      }, 500);
    }
    try {
      // Check if the user has already voted on this guide
      const existingVote = userVotes[guideId];

      if (existingVote) {
        // If the user is unvoting or changing their vote, remove the existing vote
        await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideId}/unvote`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ steamId }),
          }
        );

        // Update local vote count
        const updatedGuides = guides.map((guide) =>
          guide._id === guideId
            ? {
                ...guide,
                voteCount:
                  guide.voteCount - (existingVote === "upvote" ? 1 : -1),
              }
            : guide
        );
        setGuides(updatedGuides);

        // Reset user vote for this guide
        const updatedVotes = { ...userVotes };
        delete updatedVotes[guideId];
        setUserVotes(updatedVotes);
        localStorage.setItem("userVotes", JSON.stringify(updatedVotes));

        if (type !== "neutral") {
          // Submit new vote
          const response = await fetch(
            `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideId}/vote`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, steamId }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to submit vote.");
          }

          const updatedVoteCount = await response.json();

          // Update local vote count
          const updatedGuides2 = guides.map((guide) =>
            guide._id === guideId
              ? {
                  ...guide,
                  voteCount: updatedVoteCount.voteCount,
                }
              : guide
          );
          setGuides(updatedGuides2);

          // Update user votes and persist them in local storage
          const updatedVotes2 = { ...userVotes, [guideId]: type };
          setUserVotes(updatedVotes2);
          localStorage.setItem("userVotes", JSON.stringify(updatedVotes2));
        }
      } else {
        // Submit new vote
        if (type !== "neutral") {
          const response = await fetch(
            `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideId}/vote`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, steamId }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to submit vote.");
          }

          const updatedVoteCount = await response.json();

          // Update local vote count
          const updatedGuides = guides.map((guide) =>
            guide._id === guideId
              ? {
                  ...guide,
                  voteCount: updatedVoteCount.voteCount,
                }
              : guide
          );
          setGuides(updatedGuides);

          // Update user votes and persist them in local storage
          const updatedVotes = { ...userVotes, [guideId]: type };
          setUserVotes(updatedVotes);
          localStorage.setItem("userVotes", JSON.stringify(updatedVotes));
        }
      }
    } catch (error) {
      console.error("Error submitting vote:", error);
    }
  };

  // Filter guides based on the selected hero and category
  const filteredGuides = guides.filter(
    (guide) =>
      (selectedHero ? guide.hero === selectedHero : true) &&
      (selectedCategory ? guide.category === selectedCategory : true)
  );

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="featured-guides">
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Login Required"
        >
          <h2>You must be logged in to vote</h2>
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
      <h2>Guides</h2>
      <ul>
        {filteredGuides.map((guide) => (
          <li key={guide._id} className="guide-item">
            <div className="vote-container">
              <button
                className={`vote-button upvote ${
                  userVotes[guide._id] === "upvote" ? "voted" : ""
                }`}
                onClick={() => handleVote(guide._id, "upvote")}
              >
                <IconBxUpvote />
              </button>
              <span
                id={`vote-count-${guide._id}`} // Add an ID to the element
                className={`vote-count ${
                  userVotes[guide._id] === "upvote" ? "upvoted" : ""
                } ${userVotes[guide._id] === "downvote" ? "downvoted" : ""}`}
                onClick={() => handleVote(guide._id, "neutral")}
                style={{ cursor: "pointer" }}
              >
                {guide.voteCount || 0}
              </span>
              <button
                className={`vote-button downvote ${
                  userVotes[guide._id] === "downvote" ? "voted" : ""
                }`}
                onClick={() => handleVote(guide._id, "downvote")}
              >
                <IconBxDownvote />
              </button>
            </div>

            <div className="guide-info">
              <img
                src={`/charicon/${formatHeroNameIcon(guide.hero)}.webp`}
                alt={guide.hero}
                className="hero-icon"
              />

              <div className="guide-links">
                <Link
                  to={`/guides/${formatTitleToURL(guide.title)}`}
                  className="guide-link"
                >
                  <h3>{guide.title}</h3>
                </Link>
                {guide.username && (
                  <div className="guide-username">
                    <Link to={`/profile/${guide.username}`}>
                      {guide.username}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
const GuidesPage = () => {
  const [selectedHero, setSelectedHero] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleHeroChange = (hero) => {
    setSelectedHero(hero);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="guides-page">
      <Sidebar
        onHeroChange={handleHeroChange}
        onCategoryChange={handleCategoryChange}
      />
      <div className="content">
        <FeaturedGuides
          selectedHero={selectedHero}
          selectedCategory={selectedCategory}
        />
      </div>
    </div>
  );
};

export default GuidesPage;
