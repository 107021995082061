import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import AuthorProfiles from "../components/AuthorProfiles";
import ReactQuill from "react-quill"; // If installed via npm or yarn
import ItemSelection from "../components/ItemSelection"; // Import the new ItemSelection component

const EditGuidePage = () => {
  const { guideSlug } = useParams();
  const [title, setTitle] = useState("");
  const [hero, setHero] = useState("");
  const [content, setContent] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState(null); // State to capture errors
  const [username, setUsername] = useState(""); // State to capture the username
  const [category, setCategory] = useState("Hero"); // New state for category
  const [selectedItems, setSelectedItems] = useState([]); // Track selected items for item build
  const navigate = useNavigate();
  const [isItemBuildVisible, setIsItemBuildVisible] = useState(true);
  const [triangleClass, setTriangleClass] = useState("triangle");
  const [steamIdState, setSteamIdState] = useState("");
  const [twitchProfile, setTwitchProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");
  const [isAuthorProfilesVisible, setIsAuthorProfilesVisible] = useState(true);
  const [authorProfilesTriangleClass, setAuthorProfilesTriangleClass] =
    useState("triangle");
  const [chapters, setChapters] = useState([{ title: "", content: "" }]);
  const toggleAuthorProfilesVisibility = () => {
    setIsAuthorProfilesVisible(!isAuthorProfilesVisible);
    setAuthorProfilesTriangleClass(
      authorProfilesTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
  };
  const toggleItemBuildVisibility = () => {
    setIsItemBuildVisible(!isItemBuildVisible);
    setTriangleClass(
      triangleClass === "triangle" ? "triangle triangle-up" : "triangle"
    );
  };
  const [authorized, setAuthorized] = useState(null); // Initialize authorized to null
  const [loading, setLoading] = useState(true); // Add loading state
  const heroes = [
    "Abrams",
    "Bebop",
    "Dynamo",
    "Grey Talon",
    "Haze",
    "Infernus",
    "Ivy",
    "Kelvin",
    "Lady Geist",
    "Lash",
    "McGinnis",
    "Mo & Krill",
    "Paradox",
    "Pocket",
    "Seven",
    "Shiv",
    "Vindicta",
    "Viscous",
    "Warden",
    "Wraith",
    "Yamato",
  ];
  useEffect(() => {
    const fetchUserAndGuide = async () => {
      let userData;

      try {
        const userResponse = await fetch(
          "https://deadlock-stat-tracker-api.vercel.app/api/current-user",
          {
            credentials: "include",
          }
        );
        if (userResponse.ok) {
          userData = await userResponse.json();
          setUser(userData);
          setSteamIdState(userData?.steamId || "");
        }

        const guideResponse = await fetch(
          `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}`
        );
        if (!guideResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const guideData = await guideResponse.json();

        if (guideData.authorSteamId !== userData?.steamId) {
          setAuthorized(false);
        } else {
          setAuthorized(true);
          setTitle(guideData.title);
          setHero(guideData.hero);
          setCategory(guideData.category);
          setSelectedItems(guideData.items);
          setChapters(guideData.chapters);
          setContent(guideData.content);
          setTwitchProfile(guideData.socialLinks.twitch);
          setYoutubeProfile(guideData.socialLinks.youtube);
          setTwitterProfile(guideData.socialLinks.twitter);
        }
      } catch (error) {
        console.error("Error fetching user and guide:", error);
        setError("Failed to load guide");
      } finally {
        setLoading(false); // Set loading to false after authorization check
      }
    };
    fetchUserAndGuide();
  }, [guideSlug, navigate]);

  if (loading) {
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    ); // Render loading message while checking authorization
  }

  if (!authorized) {
    navigate("/unauthorized", { replace: true }); // Redirect to unauthorized page
    return null; // Return null to prevent rendering the page
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const socialLinks = {
      twitch: twitchProfile,
      youtube: youtubeProfile,
      twitter: twitterProfile,
    };

    const guideData = {
      title,
      hero,
      category,
      items: selectedItems,
      authorSteamId: steamIdState,
      chapters,
      socialLinks,
    };
    console.log("Guide data:", guideData); // Log guideData

    try {
      const response = await fetch(
        `https://deadlock-stat-tracker-api.vercel.app/api/guides/${guideSlug}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(guideData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedGuide = await response.json();
      console.log("Guide updated successfully:", updatedGuide);
      navigate(`/guides/${guideSlug}`);
    } catch (error) {
      console.error("Error updating guide:", error);
      setError("Failed to update guide");
    }
  };

  return (
    <div className="create-guide-page">
      <h1>Edit Guide</h1>
      <div className="main-content">
        {error && <p className="error-message">{error}</p>}{" "}
        <div className="main-content"></div>
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <label htmlFor="title">Guide Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="form-section">
            <label htmlFor="hero">Hero:</label>
            <select
              id="hero"
              value={hero}
              onChange={(e) => setHero(e.target.value)}
              required
            >
              <option value="">Select a Hero</option>
              {heroes.map((heroName, index) => (
                <option key={index} value={heroName}>
                  {heroName}
                </option>
              ))}
            </select>
          </div>

          <div className="form-section">
            <label htmlFor="category">Category:</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="Hero">Hero</option>
              <option value="General">General</option>
            </select>
          </div>
          <AuthorProfiles
            twitchProfile={twitchProfile}
            setTwitchProfile={setTwitchProfile}
            youtubeProfile={youtubeProfile}
            setYoutubeProfile={setYoutubeProfile}
            twitterProfile={twitterProfile}
            setTwitterProfile={setTwitterProfile}
            isVisible={isAuthorProfilesVisible}
            toggleVisibility={toggleAuthorProfilesVisibility}
            triangleClass={authorProfilesTriangleClass}
          />

          <div className="form-section">
            <label htmlFor="chapters">Chapters:</label>
            <button
              onClick={() =>
                setChapters([...chapters, { title: "", content: "" }])
              }
            >
              Add Chapter
            </button>
            {chapters.map((chapter, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={chapter.title}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].title = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  placeholder="Chapter Title"
                />
                <ReactQuill
                  value={chapter.content}
                  onChange={(content) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].content = content;
                    setChapters(updatedChapters);
                  }}
                  required
                  modules={EditGuidePage.modules}
                  formats={EditGuidePage.formats}
                />
                <button
                  onClick={() => {
                    const updatedChapters = [...chapters];
                    updatedChapters.splice(index, 1);
                    setChapters(updatedChapters);
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <div
            style={{ backgroundColor: "#142034" }}
            className="form-section item-build-section"
          >
            <div
              className="item-build-header"
              onClick={toggleItemBuildVisibility}
            >
              <span>Item Build</span>
              <div className={triangleClass} />
            </div>
            <div
              className={`item-build-content ${
                isItemBuildVisible ? "visible" : "hidden"
              }`}
            >
              <ItemSelection
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                className="item-selection-container"
              />
            </div>
          </div>

          <button type="submit">Save Changes</button>
        </form>
      </div>
    </div>
  );
};

EditGuidePage.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    ["link"],
    ["image"],
    [{ align: [] }],
    ["clean"],
  ],
};

EditGuidePage.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "align",
];

export default EditGuidePage;
