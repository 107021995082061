// src/pages/NewsPage.js
import React from "react";

function NewsPage() {
  return (
    <div>
      <h1>News</h1>
      <p>Stay updated with the latest news and announcements.</p>
    </div>
  );
}

export default NewsPage;
