// itemDataWithPaths.jsx

const itemDataWithPaths = {
  "Tier 1": [
    {
      id: 1,
      name: "Infuser",
      type: "Spirit",
      winrate: 53.44,
      pickrate: "6.04%",
      path: "/itemicons/spirit/500/100px-Infuser.png",
      cost: 500,
    },
    {
      id: 2,
      name: "Extra Stamina",
      type: "Vitality",
      winrate: 53.38,
      pickrate: "17.97%",
      path: "/itemicons/vitality/500/100px-Extra_Stamina.png",
      cost: 500,
    },
    {
      id: 3,
      name: "Healing Rite",
      type: "Vitality",
      winrate: 52.83,
      pickrate: "8.26%",
      path: "/itemicons/vitality/500/100px-Healing_Rite.png",
      cost: 500,
    },
    {
      id: 4,
      name: "Mystic Reach",
      type: "Spirit",
      winrate: 52.79,
      pickrate: "16.83%",
      path: "/itemicons/spirit/500/100px-Mystic_Reach.png",
      cost: 500,
    },
    {
      id: 5,
      name: "Rapid Rounds",
      type: "Weapon",
      winrate: 52.37,
      pickrate: "11.43%",
      path: "/itemicons/weapon/500/100px-Rapid_Rounds.png",
      cost: 500,
    },
    {
      id: 6,
      name: "Enduring Spirit",
      type: "Vitality",
      winrate: 52.1,
      pickrate: "22.77%",
      path: "/itemicons/vitality/500/100px-Enduring_Spirit.png",
      cost: 500,
    },
    {
      id: 7,
      name: "Close Quarters",
      type: "Weapon",
      winrate: 51.91,
      pickrate: "14.39%",
      path: "/itemicons/weapon/500/100px-Close_Quarters.png",
      cost: 500,
    },
    {
      id: 8,
      name: "Spirit Strike",
      type: "Spirit",
      winrate: 51.81,
      pickrate: "5.07%",
      path: "/itemicons/spirit/500/100px-Spirit_Strike.png",
      cost: 500,
    },
    {
      id: 9,
      name: "Ammo Scavenger",
      type: "Spirit",
      winrate: 51.58,
      pickrate: "7.15%",
      path: "/itemicons/spirit/500/100px-Ammo_Scavenger.png",
      cost: 500,
    },
    {
      id: 10,
      name: "Monster Rounds",
      type: "Weapon",
      winrate: 51.16,
      pickrate: "11.13%",
      path: "/itemicons/weapon/500/100px-Monster_Rounds.png",
      cost: 500,
    },
    {
      id: 11,
      name: "Sprint Boots",
      type: "Vitality",
      winrate: 51.1,
      pickrate: "14.22%",
      path: "/itemicons/vitality/500/100px-Sprint_Boots.png",
      cost: 500,
    },
    {
      id: 12,
      name: "Basic Magazine",
      type: "Weapon",
      winrate: 51.05,
      pickrate: "20.12%",
      path: "/itemicons/weapon/500/100px-Basic_Magazine.png",
      cost: 500,
    },
    {
      id: 13,
      name: "Extra Charge",
      type: "Spirit",
      winrate: 51.0,
      pickrate: "16.60%",
      path: "/itemicons/spirit/500/100px-Extra_Charge.png",
      cost: 500,
    },
    {
      id: 14,
      name: "Restorative Shot",
      type: "Weapon",
      winrate: 50.45,
      pickrate: "7.80%",
      path: "/itemicons/weapon/500/100px-Restorative_Shot.png",
      cost: 500,
    },
    {
      id: 15,
      name: "Headshot Booster",
      type: "Weapon",
      winrate: 50.39,
      pickrate: "14.00%",
      path: "/itemicons/weapon/500/100px-Headshot_Booster.png",
      cost: 500,
    },
    {
      id: 16,
      name: "Hollow Point Ward",
      type: "Weapon",
      winrate: 50.37,
      pickrate: "13.69%",
      path: "/itemicons/weapon/500/100px-Hollow_Point_Ward.png",
      cost: 500,
    },
    {
      id: 17,
      name: "High-Velocity Mag",
      type: "Weapon",
      winrate: 50.1,
      pickrate: "14.33%",
      path: "/itemicons/weapon/500/100px-High-Velocity_Mag.png",
      cost: 500,
    },
    {
      id: 18,
      name: "Melee Lifesteal",
      type: "Vitality",
      winrate: 50.06,
      pickrate: "3.24%",
      path: "/itemicons/vitality/500/100px-Melee_Lifesteal.png",
      cost: 500,
    },
    {
      id: 19,
      name: "Mystic Burst",
      type: "Spirit",
      winrate: 49.58,
      pickrate: "17.37%",
      path: "/itemicons/spirit/500/100px-Mystic_Burst.png",
      cost: 500,
    },
    {
      id: 20,
      name: "Extra Health",
      type: "Vitality",
      winrate: 49.26,
      pickrate: "10.97%",
      path: "/itemicons/vitality/500/100px-Extra_Health.png",
      cost: 500,
    },
    {
      id: 21,
      name: "Extra Regen",
      type: "Vitality",
      winrate: 48.62,
      pickrate: "17.35%",
      path: "/itemicons/vitality/500/100px-Extra_Regen.png",
      cost: 500,
    },
    {
      id: 22,
      name: "Extra Spirit",
      type: "Spirit",
      winrate: 48.54,
      pickrate: "15.06%",
      path: "/itemicons/spirit/500/100px-Extra_Spirit.png",
      cost: 500,
    },
  ],
  "Tier 2": [
    {
      id: 2,
      name: "Fleetfoot",
      type: "Weapon",
      winrate: 54.25,
      pickrate: "5.94%",
      path: "/itemicons/weapon/1250/100px-Fleetfoot.png",
      cost: 1250,
    },
    {
      id: 3,
      name: "Enduring Speed",
      type: "Vitality",
      winrate: 54.01,
      pickrate: "25.08%",
      path: "/itemicons/vitality/1250/100px-Enduring_Speed.png",
      cost: 1250,
    },
    {
      id: 4,
      name: "Duration Extender",
      type: "Spirit",
      winrate: 53.38,
      pickrate: "9.41%",
      path: "/itemicons/spirit/1250/100px-Duration_Extender.png",
      cost: 1250,
    },
    {
      id: 5,
      name: "Melee Charge",
      type: "Weapon",
      winrate: 53.25,
      pickrate: "3.84%",
      path: "/itemicons/weapon/1250/100px-Melee_Charge.png",
      cost: 1250,
    },
    {
      id: 6,
      name: "Mystic Vulnerability",
      type: "Spirit",
      winrate: "53.03%",
      pickrate: "14.48%",
      path: "/itemicons/spirit/1250/100px-Mystic_Vulnerability.png",
      cost: 1250,
    },
    {
      id: 7,
      name: "Slowing Hex",
      type: "Spirit",
      winrate: "52.86%",
      pickrate: "3.59%",
      path: "/itemicons/spirit/1250/100px-Slowing_Hex.png",
      cost: 1250,
    },
    {
      id: 8,
      name: "Spirit Lifesteal",
      type: "Vitality",
      winrate: "52.78%",
      pickrate: "20.65%",
      path: "/itemicons/vitality/1250/100px-Spirit_Lifesteal.png",
      cost: 1250,
    },
    {
      id: 9,
      name: "Restorative Locket",
      type: "Vitality",
      winrate: "52.50%",
      pickrate: "0.76%",
      path: "/itemicons/vitality/1250/100px-Restorative_Locket.png",
      cost: 1250,
    },
    {
      id: 10,
      name: "Health Nova",
      type: "Vitality",
      winrate: "52.36%",
      pickrate: "3.04%",
      path: "/itemicons/vitality/1250/100px-Health_Nova.png",
      cost: 1250,
    },
    {
      id: 11,
      name: "Bullet Resist Shredder",
      type: "Spirit",
      winrate: "52.12%",
      pickrate: "16.82%",
      path: "/itemicons/spirit/1250/100px-Bullet_Resist_Shredder.png",
      cost: 1250,
    },
    {
      id: 12,
      name: "Active Reload",
      type: "Weapon",
      winrate: "51.95%",
      pickrate: "13.37%",
      path: "/itemicons/weapon/1250/100px-Active_Reload.png",
      cost: 1250,
    },
    {
      id: 13,
      name: "Debuff Reducer",
      type: "Vitality",
      winrate: "51.82%",
      pickrate: "4.06%",
      path: "/itemicons/vitality/1250/100px-Debuff_Reducer.png",
      cost: 1250,
    },
    {
      id: 14,
      name: "Improved Cooldown",
      type: "Spirit",
      winrate: "51.73%",
      pickrate: "12.86%",
      path: "/itemicons/spirit/1250/100px-Improved_Cooldown.png",
      cost: 1250,
    },
    {
      id: 15,
      name: "Withering Whip",
      type: "Spirit",
      winrate: "51.57%",
      pickrate: "2.63%",
      path: "/itemicons/spirit/1250/100px-Withering_Whip.png",
      cost: 1250,
    },
    {
      id: 16,
      name: "Kinetic Dash",
      type: "Weapon",
      winrate: "51.44%",
      pickrate: "5.51%",
      path: "/itemicons/weapon/1250/100px-Kinetic_Dash.png",
      cost: 1250,
    },
    {
      id: 17,
      name: "Bullet Lifesteal",
      type: "Vitality",
      winrate: "51.39%",
      pickrate: "22.45%",
      path: "/itemicons/vitality/1250/100px-Bullet_Lifesteal.png",
      cost: 1250,
    },
    {
      id: 18,
      name: "Suppressor",
      type: "Spirit",
      winrate: "51.39%",
      pickrate: "9.85%",
      path: "/itemicons/spirit/1250/100px-Suppressor.png",
      cost: 1250,
    },
    {
      id: 20,
      name: "Berserker",
      type: "Weapon",
      winrate: "51.03%",
      pickrate: "8.47%",
      path: "/itemicons/weapon/1250/100px-Berserker.png",
      cost: 1250,
    },
    {
      id: 21,
      name: "Spirit Armor",
      type: "Vitality",
      winrate: "50.97%",
      pickrate: "16.51%",
      path: "/itemicons/vitality/1250/100px-Spirit_Armor.png",
      cost: 1250,
    },
    {
      id: 22,
      name: "Healbane",
      type: "Vitality",
      winrate: "50.85%",
      pickrate: "8.16%",
      path: "/itemicons/vitality/1250/100px-Healbane.png",
      cost: 1250,
    },
    {
      id: 23,
      name: "Swift Striker",
      type: "Weapon",
      winrate: "50.36%",
      pickrate: "7.81%",
      path: "/itemicons/weapon/1250/100px-Swift_Striker.png",
      cost: 1250,
    },
    {
      id: 24,
      name: "Bullet Armor",
      type: "Vitality",
      winrate: "50.31%",
      pickrate: "17.51%",
      path: "/itemicons/vitality/1250/100px-Bullet_Armor.png",
      cost: 1250,
    },
    {
      id: 25,
      name: "Reactive Barrier",
      type: "Vitality",
      winrate: "49.96%",
      pickrate: "4.30%",
      path: "/itemicons/vitality/1250/100px-Reactive_Barrier.png",
      cost: 1250,
    },
    {
      id: 26,
      name: "Cold Front",
      type: "Spirit",
      winrate: "49.86%",
      pickrate: "3.57%",
      path: "/itemicons/spirit/1250/100px-Cold_Front.png",
      cost: 1250,
    },
    {
      id: 27,
      name: "Quicksilver Reload",
      type: "Spirit",
      winrate: "49.83%",
      pickrate: "14.39%",
      path: "/itemicons/spirit/1250/100px-Quicksilver_Reload.png",
      cost: 1250,
    },
    {
      id: 28,
      name: "Slowing Bullets",
      type: "Weapon",
      winrate: "49.82%",
      pickrate: "12.16%",
      path: "/itemicons/weapon/1250/100px-Slowing_Bullets.png",
      cost: 1250,
    },
    {
      id: 29,
      name: "Soul Shredder Bullets",
      type: "Weapon",
      winrate: "49.71%",
      pickrate: "19.76%",
      path: "/itemicons/weapon/1250/100px-Soul_Shredder_Bullets.png",
      cost: 1250,
    },
    {
      id: 30,
      name: "Enchanter's Barrier",
      type: "Vitality",
      winrate: "49.23%",
      pickrate: "12.25%",
      path: "/itemicons/vitality/1250/100px-Enchanter's_Barrier.png",
      cost: 1250,
    },
    {
      id: 31,
      name: "Mystic Shot",
      type: "Weapon",
      winrate: "48.16%",
      pickrate: "15.13%",
      path: "/itemicons/weapon/1250/100px-Mystic_Shot.png",
      cost: 1250,
    },
    {
      id: 32,
      name: "Divine Barrier",
      type: "Vitality",
      winrate: "47.99%",
      pickrate: "0.54%",
      path: "/itemicons/vitality/1250/100px-Divine_Barrier.png",
      cost: 1250,
    },
    {
      id: 33,
      name: "Combat Barrier",
      type: "Vitality",
      winrate: "47.29%",
      pickrate: "10.16%",
      path: "/itemicons/vitality/1250/100px-Combat_Barrier.png",
      cost: 1250,
    },
    {
      id: 34,
      name: "Decay",
      type: "Spirit",
      winrate: "47.05%",
      pickrate: "1.06%",
      path: "/itemicons/spirit/1250/100px-Decay.png",
      cost: 1250,
    },
    {
      id: 35,
      name: "Long Range",
      type: "Weapon",
      winrate: "46.05%",
      pickrate: "4.33%",
      path: "/itemicons/weapon/1250/100px-Long_Range.png",
      cost: 1250,
    },
    {
      id: 36,
      name: "Return Fire",
      type: "Vitality",
      winrate: "44.87%",
      pickrate: "0.91%",
      path: "/itemicons/vitality/1250/100px-Return_Fire.png",
      cost: 1250,
    },
  ],
  "Tier 3": [
    {
      id: 1,
      name: "Superior Duration",
      type: "Spirit",
      winrate: "54.87%",
      pickrate: "5.11%",
      path: "/itemicons/spirit/3000/100px-Superior_Duration.png",
      cost: 3000,
    },
    {
      id: 2,
      name: "Heroic Aura",
      type: "Weapon",
      winrate: "54.49%",
      pickrate: "2.37%",
      path: "/itemicons/weapon/3000/100px-Heroic_Aura.png",
      cost: 3000,
    },
    {
      id: 3,
      name: "Torment Pulse",
      type: "Spirit",
      winrate: "54.47%",
      pickrate: "4.88%",
      path: "/itemicons/spirit/3000/100px-Torment_Pulse.png",
      cost: 3000,
    },
    {
      id: 4,
      name: "Improved Reach",
      type: "Spirit",
      winrate: "54.26%",
      pickrate: "8.46%",
      path: "/itemicons/spirit/3000/100px-Improved_Reach.png",
      cost: 3000,
    },
    {
      id: 5,
      name: "Hunter's Aura",
      type: "Weapon",
      winrate: "54.13%",
      pickrate: "3.30%",
      path: "/itemicons/weapon/3000/100px-Hunter's_Aura.png",
      cost: 3000,
    },
    {
      id: 6,
      name: "Lifestrike",
      type: "Vitality",
      winrate: "54.08%",
      pickrate: "2.67%",
      path: "/itemicons/vitality/3000/100px-Lifestrike.png",
      cost: 3000,
    },
    {
      id: 7,
      name: "Majestic Leap",
      type: "Vitality",
      winrate: "53.74%",
      pickrate: "2.25%",
      path: "/itemicons/vitality/3000/100px-Majestic_Leap.png",
      cost: 3000,
    },
    {
      id: 8,
      name: "Intensifying Magazine",
      type: "Weapon",
      winrate: "52.80%",
      pickrate: "7.61%",
      path: "/itemicons/weapon/3000/100px-Intensifying_Magazine.png",
      cost: 3000,
    },
    {
      id: 9,
      name: "Rescue Beam",
      type: "Vitality",
      winrate: "52.65%",
      pickrate: "0.82%",
      path: "/itemicons/vitality/3000/100px-Rescue_Beam.png",
      cost: 3000,
    },
    {
      id: 10,
      name: "Point Blank",
      type: "Weapon",
      winrate: "52.39%",
      pickrate: "9.38%",
      path: "/itemicons/weapon/3000/100px-Point_Blank.png",
      cost: 3000,
    },
    {
      id: 11,
      name: "Tesla Bullets",
      type: "Weapon",
      winrate: "52.28%",
      pickrate: "12.80%",
      path: "/itemicons/weapon/3000/100px-Tesla_Bullets.png",
      cost: 3000,
    },
    {
      id: 12,
      name: "Superior Cooldown",
      type: "Spirit",
      winrate: "51.97%",
      pickrate: "15.01%",
      path: "/itemicons/spirit/3000/100px-Superior_Cooldown.png",
      cost: 3000,
    },
    {
      id: 13,
      name: "Titanic Magazine",
      type: "Weapon",
      winrate: "51.90%",
      pickrate: "10.39%",
      path: "/itemicons/weapon/3000/100px-Titanic_Magazine.png",
      cost: 3000,
    },
    {
      id: 14,
      name: "Pristine Emblem",
      type: "Weapon",
      winrate: "51.55%",
      pickrate: "8.00%",
      path: "/itemicons/weapon/3000/100px-Pristine_Emblem.png",
      cost: 3000,
    },
    {
      id: 15,
      name: "Veil Walker",
      type: "Vitality",
      winrate: "51.39%",
      pickrate: "3.02%",
      path: "/itemicons/vitality/3000/100px-Veil_Walker.png",
      cost: 3000,
    },
    {
      id: 16,
      name: "Surge of Power",
      type: "Spirit",
      winrate: "51.30%",
      pickrate: "5.03%",
      path: "/itemicons/spirit/3000/100px-Surge_of_Power.png",
      cost: 3000,
    },
    {
      id: 17,
      name: "Warp Stone",
      type: "Weapon",
      winrate: "51.20%",
      pickrate: "4.78%",
      path: "/itemicons/weapon/3000/100px-Warp_Stone.png",
      cost: 3000,
    },
    {
      id: 18,
      name: "Improved Spirit",
      type: "Spirit",
      winrate: "50.90%",
      pickrate: "12.55%",
      path: "/itemicons/spirit/3000/100px-Improved_Spirit.png",
      cost: 3000,
    },
    {
      id: 19,
      name: "Mystic Slow",
      type: "Spirit",
      winrate: "50.89%",
      pickrate: "5.39%",
      path: "/itemicons/spirit/3000/100px-Mystic_Slow.png",
      cost: 3000,
    },
    {
      id: 20,
      name: "Burst Fire",
      type: "Weapon",
      winrate: "50.71%",
      pickrate: "8.04%",
      path: "/itemicons/weapon/3000/100px-Burst_Fire.png",
      cost: 3000,
    },
    {
      id: 21,
      name: "Improved Spirit Armor",
      type: "Vitality",
      winrate: "50.67%",
      pickrate: "11.09%",
      path: "/itemicons/vitality/3000/100px-Improved_Spirit_Armor.png",
      cost: 3000,
    },
    {
      id: 22,
      name: "Superior Stamina",
      type: "Vitality",
      winrate: "50.34%",
      pickrate: "8.41%",
      path: "/itemicons/vitality/3000/100px-Superior_Stamina.png",
      cost: 3000,
    },
    {
      id: 23,
      name: "Improved Bullet Armor",
      type: "Vitality",
      winrate: "49.89%",
      pickrate: "12.86%",
      path: "/itemicons/vitality/3000/100px-Improved_Bullet_Armor.png",
      cost: 3000,
    },
    {
      id: 24,
      name: "Escalating Resilience",
      type: "Weapon",
      winrate: "49.77%",
      pickrate: "1.07%",
      path: "/itemicons/weapon/3000/100px-Escalating_Resilience.png",
      cost: 3000,
    },
    {
      id: 25,
      name: "Toxic Bullets",
      type: "Weapon",
      winrate: "49.61%",
      pickrate: "7.58%",
      path: "/itemicons/weapon/3000/100px-Toxic_Bullets.png",
      cost: 3000,
    },
    {
      id: 26,
      name: "Improved Burst",
      type: "Spirit",
      winrate: "49.48%",
      pickrate: "13.73%",
      path: "/itemicons/spirit/3000/100px-Improved_Burst.png",
      cost: 3000,
    },
    {
      id: 27,
      name: "Rapid Recharge",
      type: "Spirit",
      winrate: "49.28%",
      pickrate: "10.20%",
      path: "/itemicons/spirit/3000/100px-Rapid_Recharge.png",
      cost: 3000,
    },
    {
      id: 28,
      name: "Fortitude",
      type: "Vitality",
      winrate: "48.57%",
      pickrate: "6.29%",
      path: "/itemicons/vitality/3000/100px-Fortitude.png",
      cost: 3000,
    },
    {
      id: 29,
      name: "Ethereal Shift",
      type: "Spirit",
      winrate: "48.29%",
      pickrate: "1.49%",
      path: "/itemicons/spirit/3000/100px-Ethereal_Shift.png",
      cost: 3000,
    },
    {
      id: 30,
      name: "Debuff Remover",
      type: "Vitality",
      winrate: "48.03%",
      pickrate: "0.83%",
      path: "/itemicons/vitality/3000/100px-Debuff_Remover.png",
      cost: 3000,
    },
    {
      id: 31,
      name: "Knockdown",
      type: "Spirit",
      winrate: "47.59%",
      pickrate: "1.22%",
      path: "/itemicons/spirit/3000/100px-Knockdown.png",
      cost: 3000,
    },
    {
      id: 32,
      name: "Alchemical Fire",
      type: "Weapon",
      winrate: "46.76%",
      pickrate: "0.49%",
      path: "/itemicons/weapon/3000/100px-Alchemical_Fire.png",
      cost: 3000,
    },
    {
      id: 33,
      name: "Sharpshooter",
      type: "Weapon",
      winrate: "46.53%",
      pickrate: "6.85%",
      path: "/itemicons/weapon/3000/100px-Sharpshooter.png",
      cost: 3000,
    },
    {
      id: 34,
      name: "Silence Glyph",
      type: "Spirit",
      winrate: "46.51%",
      pickrate: "0.62%",
      path: "/itemicons/spirit/3000/100px-Silence_Glyph.png",
      cost: 3000,
    },
    {
      id: 35,
      name: "Metal Skin",
      type: "Vitality",
      winrate: "46.04%",
      pickrate: "0.96%",
      path: "/itemicons/vitality/3000/100px-Metal_Skin.png",
      cost: 3000,
    },
  ],
  "Tier 4": [
    {
      id: 1,
      name: "Ricochet",
      type: "Weapon",
      winrate: "57.58%",
      pickrate: "3.72%",
      path: "/itemicons/weapon/6300/100px-Ricochet.png",
      cost: 6300,
    },
    {
      id: 2,
      name: "Siphon Bullets",
      type: "Weapon",
      winrate: "57.21%",
      pickrate: "4.44%",
      path: "/itemicons/weapon/6300/100px-Siphon_Bullets.png",
      cost: 6300,
    },
    {
      id: 3,
      name: "Escalating Exposure",
      type: "Spirit",
      winrate: "56.74%",
      pickrate: "3.11%",
      path: "/itemicons/spirit/6300/100px-Escalating_Exposure.png",
      cost: 6300,
    },
    {
      id: 4,
      name: "Mystic Reverb",
      type: "Spirit",
      winrate: "56.19%",
      pickrate: "1.82%",
      path: "/itemicons/spirit/6300/100px-Mystic_Reverb.png",
      cost: 6300,
    },
    {
      id: 5,
      name: "Diviner's Kevlar",
      type: "Spirit",
      winrate: "56.16%",
      pickrate: "2.33%",
      path: "/itemicons/spirit/6300/100px-Diviner's_Kevlar.png",
      cost: 6300,
    },
    {
      id: 6,
      name: "Leech",
      type: "Vitality",
      winrate: "55.83%",
      pickrate: "7.68%",
      path: "/itemicons/vitality/6300/100px-Leech.png",
      cost: 6300,
    },
    {
      id: 7,
      name: "Vampiric Burst",
      type: "Weapon",
      winrate: "55.64%",
      pickrate: "0.82%",
      path: "/itemicons/weapon/6300/100px-Vampiric_Burst.png",
      cost: 6300,
    },
    {
      id: 8,
      name: "Echo Shard",
      type: "Spirit",
      winrate: "55.50%",
      pickrate: "0.67%",
      path: "/itemicons/spirit/6300/100px-Echo_Shard.png",
      cost: 6300,
    },
    {
      id: 9,
      name: "Phantom Strike",
      type: "Vitality",
      winrate: "55.34%",
      pickrate: "0.45%",
      path: "/itemicons/vitality/6300/100px-Phantom_Strike.png",
      cost: 6300,
    },
    {
      id: 10,
      name: "Glass Cannon",
      type: "Weapon",
      winrate: "54.88%",
      pickrate: "5.77%",
      path: "/itemicons/weapon/6300/100px-Glass_Cannon.png",
      cost: 6300,
    },
    {
      id: 11,
      name: "Refresher",
      type: "Spirit",
      winrate: "54.86%",
      pickrate: "0.87%",
      path: "/itemicons/spirit/6300/100px-Refresher.png",
      cost: 6300,
    },
    {
      id: 12,
      name: "Frenzy",
      type: "Weapon",
      winrate: "54.65%",
      pickrate: "1.69%",
      path: "/itemicons/weapon/6300/100px-Frenzy.png",
      cost: 6300,
    },
    {
      id: 13,
      name: "Inhibitor",
      type: "Vitality",
      winrate: "54.62%",
      pickrate: "1.70%",
      path: "/itemicons/vitality/6300/100px-Inhibitor.png",
      cost: 6300,
    },
    {
      id: 14,
      name: "Unstoppable",
      type: "Vitality",
      winrate: "54.44%",
      pickrate: "0.97%",
      path: "/itemicons/vitality/6300/100px-Unstoppable.png",
      cost: 6300,
    },
    {
      id: 15,
      name: "Colossus",
      type: "Vitality",
      winrate: "54.28%",
      pickrate: "0.42%",
      path: "/itemicons/vitality/6300/100px-Colossus.png",
      cost: 6300,
    },
    {
      id: 16,
      name: "Crippling Headshot",
      type: "Weapon",
      winrate: "54.16%",
      pickrate: "3.39%",
      path: "/itemicons/weapon/6300/100px-Crippling_Headshot.png",
      cost: 6300,
    },
    {
      id: 17,
      name: "Boundless Spirit",
      type: "Spirit",
      winrate: "54.14%",
      pickrate: "4.28%",
      path: "/itemicons/spirit/6300/100px-Boundless_Spirit.png",
      cost: 6300,
    },
    {
      id: 18,
      name: "Soul Rebirth",
      type: "Vitality",
      winrate: "53.42%",
      pickrate: "0.73%",
      path: "/itemicons/vitality/6300/100px-Soul_Rebirth.png",
      cost: 6300,
    },
    {
      id: 19,
      name: "Spiritual Overflow",
      type: "Weapon",
      winrate: "52.59%",
      pickrate: "2.06%",
      path: "/itemicons/weapon/6300/100px-Spiritual_Overflow.png",
      cost: 6300,
    },
    {
      id: 20,
      name: "Magic Carpet",
      type: "Spirit",
      winrate: "51.49%",
      pickrate: "0.08%",
      path: "/itemicons/spirit/6300/100px-Magic_Carpet.png",
      cost: 6300,
    },
    {
      id: 21,
      name: "Shadow Weave",
      type: "Vitality",
      winrate: "51.40%",
      pickrate: "0.09%",
      path: "/itemicons/vitality/6300/100px-Shadow_Weave.png",
      cost: 6300,
    },
    {
      id: 22,
      name: "Silencer",
      type: "Weapon",
      winrate: "51.20%",
      pickrate: "0.79%",
      path: "/itemicons/weapon/6300/100px-Silencer.png",
      cost: 6300,
    },
    {
      id: 23,
      name: "Curse",
      type: "Spirit",
      winrate: "47.47%",
      pickrate: "0.63%",
      path: "/itemicons/spirit/6300/100px-Curse.png",
      cost: 6300,
    },
    {
      id: 24,
      name: "Lucky Shot",
      type: "Weapon",
      winrate: 55.19,
      pickrate: "5.83%",
      path: "/itemicons/weapon/6300/100px-Lucky_Shot.png",
      cost: 6300,
    },
  ],
};
Object.keys(itemDataWithPaths).forEach((tier) => {
  itemDataWithPaths[tier].forEach((item) => {
    item.winrate = parseFloat(item.winrate);
  });
});
Object.keys(itemDataWithPaths).forEach((tier) => {
  itemDataWithPaths[tier].forEach((item) => {
    item.pickrate = parseFloat(item.pickrate.replace("%", ""));
  });
});

export default itemDataWithPaths;
